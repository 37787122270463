<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { layouts } from './index'

import DVersionCheckBanner from '@/components/DVersionCheckBanner.vue'

const route = useRoute()

const layout = computed(() => {
    const layout = (route.meta.layout as keyof typeof layouts) || 'Loading'
    if (!layouts[layout]) {
        throw new Error(`Layout ${layout} does not exist`)
    }
    return layouts[layout]
})

const routeProps = computed(() => route.meta.props as object)
</script>

<template>
    <DVersionCheckBanner v-if="route.meta.layout" />
    <Component :is="layout" v-bind="routeProps">
        <slot />
    </Component>
</template>
