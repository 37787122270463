<script setup lang="ts">
import { ref, computed, onUpdated } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { onlineManager } from '@tanstack/vue-query'
import { useAuthStore } from '@/stores/authStore'
import { useProfileQueries } from '@/queries/profile'
import OfflineError from '../views/OfflineError.vue'
import FullScreenSpinner from '../components/FullScreenSpinner.vue'
import BaseIcon from '../components/BaseIcon.vue'
import DSideNav from '../components/DSideNav.vue'
import { useNotificationsInbox, type NotificationItem } from '@/modules/inbox/queries'

const router = useRouter()
const authStore = useAuthStore()
const { get: getCurrentUser } = useProfileQueries()

const { list: listAllNotifications } = useNotificationsInbox()
const { data: notificationsList } = listAllNotifications()
const unreadInboxCount = computed(
    () => notificationsList.value?.filter((item: NotificationItem) => !item.isRead).length
)

const { data: user, isPending: isUserLoading } = getCurrentUser()
const { isAuthenticated } = storeToRefs(authStore)

const isSidebarVisible = ref(false)
const isOnline = ref(onlineManager.isOnline())

onlineManager.subscribe(() => {
    isOnline.value = onlineManager.isOnline()
})

onUpdated(() => {
    if (!isAuthenticated.value) {
        router.push('/login')
    }
})
</script>

<template>
    <span :class="{ hidden: isOnline }">
        <OfflineError />
    </span>

    <FullScreenSpinner v-if="(isOnline && isUserLoading) || !user" />
    <div v-else :class="{ hidden: !isOnline }">
        <!-- Static sidebar for desktop -->
        <nav class="z-0 hidden border-r border-gray-200 bg-white lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
            <DSideNav
                class="flex-none"
                :user-id="user.id"
                :user="authStore.mappedUser"
                :unread-inbox-count="unreadInboxCount"
            />
        </nav>
        <!-- Sidebar for smaller screens -->
        <PSidebar v-model:visible="isSidebarVisible" :pt="{ root: { class: 'z-sidebar' }, content: { class: 'px-0' } }">
            <DSideNav
                class="w-full flex-none border-none"
                :user-id="user.id"
                :user="authStore.mappedUser"
                :unread-inbox-count="unreadInboxCount"
            />
        </PSidebar>

        <!-- Header navbar -->
        <div class="flex min-h-screen flex-1 flex-col lg:pl-64">
            <div class="mb-0.5 flex h-16 shrink-0 items-center bg-white p-4 shadow lg:hidden">
                <div class="p-0.5 hover:cursor-pointer hover:text-gray-700" @click="isSidebarVisible = true">
                    <BaseIcon icon="menu-2" />
                </div>
            </div>
            <main class="h-screen-minus-mobile-header relative flex-1 bg-slate-50 lg:h-screen">
                <slot />
            </main>
        </div>
    </div>
</template>
