import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'

export const useKeywords = () => {
    const {
        data: keywords,
        isFetching,
        isLoading,
        isRefetching,
    } = useQuery({
        queryKey: ['listAllKeywords'],
        queryFn: () => client.keyword.list.query(),
        initialData: [],
        queries: ['Keyword'],
    })

    return {
        keywords,
        isFetching,
        isLoading,
        isRefetching,
    }
}
