import { computed, toValue } from 'vue'
import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'
import { TeamTypeEnum } from '@prisma/client'

export const useTeams = (type?: TeamTypeEnum) => {
    const { data, isLoading, isPending, isFetching, isRefetching } = useQuery({
        queryKey: ['listAllTeams'],
        queryFn: () => client.team.list.query(),
        initialData: [],
        queries: ['Team'],
    })

    const getTeamsByType = computed(() =>
        type ? data.value.filter((team) => team.type === toValue(type)) : data.value
    )

    return {
        teams: getTeamsByType,
        isLoading,
        isPending,
        isFetching,
        isRefetching,
    }
}
