import { type RouteLocationNormalized } from 'vue-router'

export function handleMissingFilesErrors(error: Error, to: RouteLocationNormalized) {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        window.location.href = to.fullPath
    }
}
