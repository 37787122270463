import { OpportunityPriorityEnum, ProductPriorityEnum } from '@prisma/client'
import { useI18n } from 'vue-i18n'
import { useIcons } from '../selector'

export type PriorityEnumUnion = OpportunityPriorityEnum | ProductPriorityEnum
export type EntityPriorityType = 'opportunity' | 'product'

const opportunityKeys = Object.values(OpportunityPriorityEnum).reverse()
const dataProductKeys = Object.values(ProductPriorityEnum)

export const usePriorities = (type: EntityPriorityType) => {
    const { t } = useI18n()
    const { priorityIcons } = useIcons()
    const priorityKeys: PriorityEnumUnion[] = type === 'opportunity' ? opportunityKeys : dataProductKeys

    return priorityKeys.map((key) => {
        return {
            id: key,
            label: t(`priorities.${key}`),
            icon: priorityIcons[key].icon,
            iconColor: priorityIcons[key].color,
        }
    })
}
