import { createAuth0 } from '@auth0/auth0-vue'

const auth0 = createAuth0({
    domain: window.env.AUTH0_DOMAIN,
    clientId: window.env.AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: window.env.AUTH0_API_AUDIENCE,
        max_age: 604800, // 1 week
    },
    cacheLocation: 'localstorage',
})

export default auth0
