<script lang="ts" setup>
import { computed } from 'vue'

import { EntityTypeEnumRefactored, type SelectorItem, useSelectorEntityType } from '@/composables/selector'

import DSelectorGeneric from './DSelectorGeneric.vue'
import BaseIcon from './BaseIcon.vue'
import DButton, { type DButtonType } from './DButton.vue'

const props = withDefaults(
    defineProps<{
        modelValue?: EntityTypeEnumRefactored
        placeholder?: string
        buttonType?: DButtonType
        testid?: string
        disabled?: boolean
    }>(),
    {
        testid: 'd-selector-entity-type',
        buttonType: 'ghost',
        disabled: false,
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value?: EntityTypeEnumRefactored): void
    (name: 'select', value?: EntityTypeEnumRefactored): void
}>()

const { entityTypeSelectorItems: options } = useSelectorEntityType()
const selected = computed(() => options.filter((item) => item.id === props.modelValue))

function onModelUpdate(item?: SelectorItem[]) {
    if (!item) return
    emit('update:modelValue', item?.[0].id as EntityTypeEnumRefactored)
}
</script>

<template>
    <span
        v-tooltip.bottom="{
            value: $t(`components.SelectorEntityType.disabled.${modelValue}`),
            disabled: !disabled,
        }"
    >
        <DSelectorGeneric
            :disabled="disabled"
            :model-value="selected"
            :options="options"
            :filter="false"
            @update:model-value="onModelUpdate"
        >
            <DButton :type="buttonType ?? 'ghost'" :data-testid="testid" :disabled="disabled">
                <BaseIcon v-if="selected[0]?.icon" :icon="selected[0]?.icon" class="text-slate-500" size="md" />
                {{ modelValue ? $t(`components.SelectorEntityType.${modelValue}`) : placeholder }}
            </DButton>
        </DSelectorGeneric>
    </span>
</template>
