import { type RouteLocationNormalized } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'
import { client } from '@/plugins/trpc'

export async function featureToggleGuard(to: RouteLocationNormalized) {
    const authRequiredInRouteOrParent = to.matched.map((m) => m.meta.requiresAuth).includes(true)
    if (!authRequiredInRouteOrParent) return

    const routeToggles = to.matched.flatMap((m) => m.meta.featureToggles || [])
    if (!routeToggles.length) return

    // this is the only way to use queryClient in a guard
    // useQuery cannot be used outside of a component
    const queryClient = useQueryClient()
    const featureToggles = await queryClient.fetchQuery({
        queryKey: ['featureToggles'],
        queryFn: () => client.featureToggle.list.query(),
        staleTime: 1000 * 60 * 5,
    })
    const hasAllFeatureToggles = routeToggles.every((ft) => featureToggles.includes(ft))

    if (!hasAllFeatureToggles) {
        return to.meta.noFeatureToggleRedirectRoute || { name: 'not-found' }
    }
}
