import { type Plugin, watch } from 'vue'
import {
    createTRPCProxyClient,
    httpBatchLink,
    type CreateTRPCProxyClient,
    createWSClient,
    splitLink,
    wsLink,
} from '@trpc/client'
import superjson from 'superjson'
import { Prisma } from '@prisma/client'
import { storeToRefs } from 'pinia'
import { type ToastServiceMethods } from 'primevue/toastservice'
import { sharedConfig } from '@mindfuel/server/src/common/config'
import type { AppRouter } from '@mindfuel/server/src/trpc'
import { useAuthStore } from '@/stores/authStore'
import i18n from '@/plugins/i18n'
import { useCustomToast } from '@/composables/toast'

superjson.registerCustom<Prisma.Decimal, string>(
    {
        isApplicable: (v): v is Prisma.Decimal => Prisma.Decimal.isDecimal(v),
        serialize: (v) => v.toJSON(),
        deserialize: (v) => new Prisma.Decimal(v),
    },
    'decimal'
)

let toast: ToastServiceMethods

async function authenticateWithToken(token: string | undefined) {
    if (!token) return

    const { t } = i18n.global
    const { showErrorToast } = useCustomToast(toast)

    return client.ws.auth.subscribe(
        { token },
        {
            onError: () => {
                showErrorToast(t('error.ws.auth'))
            },
        }
    )
}

async function authenticateWebSocket() {
    const authStore = useAuthStore()

    if (!authStore.isAuthenticated) {
        const { isAuthenticated } = storeToRefs(authStore)
        watch(isAuthenticated, async (newValue: boolean) => {
            if (!newValue) {
                return
            }
            const token = await authStore.getToken()
            authenticateWithToken(token)
        })
        return
    }
    const token = await authStore.getToken()
    authenticateWithToken(token)
}

export let client: CreateTRPCProxyClient<AppRouter>

export const trpc: Plugin = {
    install: (app) => {
        toast = app.config.globalProperties.$toast

        const options = {
            transformer: superjson,
            links: [
                splitLink({
                    condition(op) {
                        return op.type === 'subscription'
                    },
                    true: wsLink({
                        client: createWSClient({
                            url: `${window.env.ENV_NAME === 'local' ? 'ws://' : 'wss://'}${location.host}/trpc`,
                            onOpen: authenticateWebSocket,
                        }),
                    }),
                    false: httpBatchLink({
                        url: sharedConfig.trpcPrefix,
                        async headers() {
                            const authStore = useAuthStore()
                            const token = await authStore.getToken()
                            return token ? { Authorization: `Bearer ${token}` } : {}
                        },
                        maxURLLength: 2048,
                    }),
                }),
            ],
        }

        client = createTRPCProxyClient<AppRouter>(options)
        app.config.globalProperties.$client = client
        app.provide('trpc', { $client: client })
    },
}

declare module 'vue' {
    interface ComponentCustomProperties {
        $client: typeof client
    }
}
