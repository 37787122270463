import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/authStore'

export function checkForOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    const authStore = useAuthStore()
    const { mappedUser } = storeToRefs(authStore)

    const onboardingCompleted = mappedUser.value.onboardingCompleted
    if (onboardingCompleted) {
        return next({ name: 'mission-control', query: to.query })
    }
    next()
}
