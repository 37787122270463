<script lang="ts" setup>
import { ref, computed } from 'vue'

import type { SelectorItem } from '@/composables/selector'

import BaseIcon from '@/components/BaseIcon.vue'
import DButton, { type DButtonType } from '@/components/DButton.vue'
import DTooltip from '@/components/DTooltip.vue'
import DSelectorGeneric from '@/components/DSelectorGeneric.vue'

const props = withDefaults(
    defineProps<{
        modelValue?: string[]
        metrics?: { id: string; label: string }[]
        placeholder?: string
        filterPlaceholder?: string
        buttonType?: DButtonType
    }>(),
    { metrics: () => [], buttonType: 'ghost' }
)

const emit = defineEmits<{
    (name: 'update:modelValue', value?: string | string[]): void
    (name: 'select', value: string): void
    (name: 'unselect', value: string): void
}>()

const selected = computed(() => props.metrics?.filter(({ id }) => props.modelValue?.includes(id)))

function onModelUpdate(value: SelectorItem[] | undefined) {
    if (!value) return

    const ids = value.map((item) => item.id).filter(Boolean)
    emit('update:modelValue', ids)
}

const metricTooltip = ref()
const isSelectorOpen = ref(false)
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="metrics"
        multiple
        max-width="sm"
        :filter-placeholder="filterPlaceholder"
        @show="isSelectorOpen = true"
        @hide="isSelectorOpen = false"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect', $event)"
        @update:model-value="onModelUpdate($event)"
    >
        <slot>
            <DButton v-if="!selected || !selected.length" :type="buttonType ?? 'ghost'">
                <BaseIcon v-if="modelValue" icon="metric" class="text-slate-500" size="md" />
                <span class="truncate">{{ placeholder }}</span>
            </DButton>

            <div v-else>
                <DButton
                    id="metric"
                    :type="buttonType ?? 'ghost'"
                    class="max-w-56"
                    @mouseenter="metricTooltip?.show"
                    @mouseleave="metricTooltip?.hide"
                >
                    <BaseIcon v-if="modelValue" icon="metric" class="text-slate-500" size="md" />
                    <span class="truncate">
                        {{
                            selected.length > 1
                                ? $t('components.SelectorMetrics.multipleSelected', { count: selected.length })
                                : selected[0]?.label
                        }}
                    </span>
                </DButton>

                <DTooltip v-if="!isSelectorOpen" ref="metricTooltip" placement="top-end" anchor="metric">
                    <ul class="flex flex-col gap-2 p-1" :class="{ 'list-inside list-disc': selected.length > 1 }">
                        <li v-for="metric in selected" :key="metric?.id" class="truncate">
                            {{ metric?.label }}
                        </li>
                    </ul>
                </DTooltip>
            </div>
        </slot>
    </DSelectorGeneric>
</template>
