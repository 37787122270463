<script lang="ts" setup>
import DButton from '@/components/DButton.vue'
withDefaults(
    defineProps<{
        maxCharacters?: number
        textContentLength?: number
        hasCancelButton?: boolean
        saveButtonLabel?: string
        saveButtonIcon?: string
        required?: boolean
    }>(),
    {
        show: false,
        maxCharacters: 0,
        textContentLength: 0,
        hasCancelButton: true,
        saveButtonLabel: '',
    }
)

const emit = defineEmits<{
    (name: 'cancel-change'): void
    (name: 'save-change'): void
}>()
</script>
<template>
    <footer class="mt-2 flex items-center justify-end gap-x-2">
        <div v-if="maxCharacters && textContentLength > maxCharacters" class="p-error">
            {{ $t('formValidation.maxChars', [maxCharacters]) }}
        </div>
        <DButton
            v-if="hasCancelButton"
            :label="$t('cancel')"
            type="ghost"
            class="ml-auto w-fit capitalize"
            @click="emit('cancel-change')"
        />
        <DButton
            data-testid="comment-confirm-button"
            class="font-semibold"
            :icon="saveButtonIcon"
            type="confirm"
            :title="$t('shortcuts.commandOrCtrlEnter')"
            :disabled="required && !textContentLength"
            @click="emit('save-change')"
        />
    </footer>
</template>
