import { useMutation } from '@/composables/vue-query-with-magic'
import { client } from '@/plugins/trpc'

export function useCreateEntityQueries() {
    return {
        createOpportunity() {
            return useMutation({
                mutationFn: client.opportunity.create.mutate,
                mutates: ['Opportunity'],
            })
        },
        createDataProduct() {
            return useMutation({
                mutationFn: client.dataProduct.create.mutate,
                mutates: ['DataProduct'],
            })
        },
    }
}
