<script setup lang="ts">
import { computed } from 'vue'
import type { Size } from '@/composables/sizing'

const props = withDefaults(
    defineProps<{
        forId?: string
        label?: string
        size?: Size
        helpText?: string
        paddingBottom?: boolean
        optional?: boolean
    }>(),
    {
        size: 'lg',
        optional: false,
    }
)

const textStyle = computed(
    () =>
        ({
            sm: 'text-xs font-medium',
            md: 'text-sm font-medium',
            lg: 'text-base font-normal',
        })[props.size]
)
</script>

<template>
    <div class="flex flex-col gap-1" :class="[{ 'pb-1.5': paddingBottom }, textStyle]">
        <label :for="forId" class="flex flex-wrap gap-1 text-slate-700">
            <slot>{{ label }}</slot>
            <span v-if="optional" class="text-slate-400">{{ $t('formValidation.optional') }}</span>
        </label>

        <p v-if="helpText" :id="`${forId}-help`" class="text-slate-500">
            <slot name="help-text">{{ helpText }}</slot>
        </p>
    </div>
</template>
