import { createI18n } from 'vue-i18n'
import en from '../lang/en.json'
type en = typeof en

declare module 'vue-i18n' {
    export interface DefineLocaleMessage extends en {
        // I had to keep at least one property here, otherwise the type extend doesn't work
        refresh: string
    }
}

export default createI18n({
    availableLocales: ['en'],
    locale: 'en',
    legacy: false,
    messages: {
        en: en,
    },
})
