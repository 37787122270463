import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'

export const useMetricTypes = () => {
    const {
        data: metricTypes,
        isLoading,
        isFetching,
        isRefetching,
    } = useQuery({
        queryKey: ['listAllMetricTypes'],
        queryFn: () => client.metricType.list.query(),
        initialData: [],
        queries: ['MetricType'],
    })

    return {
        metricTypes,
        isLoading,
        isFetching,
        isRefetching,
    }
}
