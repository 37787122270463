<script lang="ts" setup>
/**
 * TODO: This is a temporary component. Delete when the 'DDropdown' component is implemented.
 */
import type { MenuItem } from 'primevue/menuitem'
import type { PassThrough } from 'primevue/ts-helpers'
import type { ListboxPassThroughOptions, ListboxChangeEvent } from 'primevue/listbox'
import { ref } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'

defineProps<{
    modelValue?: MenuItem
    options?: MenuItem[]
}>()
const emit = defineEmits<{
    (name: 'change', value: { value: number | boolean }): void
    (name: 'toggle-menu', value: { event: Event; visible: boolean }): void
}>()

const overlayPanel = ref()
const toggleOverlay = (event: Event) => {
    overlayPanel.value.toggle(event)
    emit('toggle-menu', { event, visible: overlayPanel.value.visible })
}

const onChange = (event: ListboxChangeEvent) => {
    emit('change', { value: event.value?.value })
    overlayPanel.value?.toggle()
}

const listboxPT: PassThrough<ListboxPassThroughOptions> = {
    root: {
        class: 'p-0 min-w-[144px] border-none whitespace-nowrap shadow-md',
    },
    list: {
        class: ['texteditor-dropdown-menu p-0.5 space-y-0.5'],
    },
    item: {
        class: 'hover:rounded px-4 py-2 bg-white hover:bg-slate-100',
    },
}
</script>

<template>
    <div data-testid="text-editor-dropdown" tabindex="0" @click="toggleOverlay">
        <div class="flex cursor-pointer items-center gap-x-2 whitespace-nowrap px-2 py-1 text-sm text-slate-500">
            {{ modelValue?.label }}
            <BaseIcon icon="chevron-down" class="text-slate-500" :class="{ 'rotate-180': overlayPanel?.visible }" />
        </div>
    </div>

    <POverlayPanel ref="overlayPanel">
        <PListbox :value="modelValue" :options="options" option-label="label" :pt="listboxPT" @change="onChange">
            <template #option="{ option }">
                <div class="flex items-center justify-between gap-x-8 text-sm text-slate-700">
                    <span>
                        {{ option.label }}
                    </span>
                    <BaseIcon v-if="option.id === modelValue?.id" icon="check" class="text-slate-500" />
                </div>
            </template>
        </PListbox>
    </POverlayPanel>
</template>
