import { createApp } from 'vue'
import App from './App.vue'
import { trpc } from './plugins/trpc'
import Router from './plugins/router'
import I18n from './plugins/i18n'
import PrimeVue from './plugins/primevue'
import ChartJS from './plugins/chartjs'
import Tabler from './plugins/tabler'
import auth0 from './plugins/auth0'
import loadRemoteConfig from './plugins/remoteConfig'
import pinia from './plugins/pinia'
import heap from './plugins/heap'
import sentry from './plugins/sentry'
import marker from './plugins/marker'
import vueQuery from './plugins/vueQuery'

import 'primevue/resources/primevue.min.css'
import '@/assets/theme.css'
import '@/assets/animation.css'

const app = createApp(App)

app.config.globalProperties.window = window

loadRemoteConfig()
app.use(auth0)

const router = Router(app)
sentry(app, router)

app.use(router)
app.use(I18n)
app.use(pinia)
app.use(PrimeVue)
app.use(ChartJS)
app.use(Tabler)
app.use(trpc)
app.use(vueQuery, {
    router,
    toast: app.config.globalProperties.$toast,
})

app.use(heap)
app.use(marker)

app.mount('#app')
