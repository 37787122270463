import { client } from '@/plugins/trpc'
import { useQuery, useMutation } from '@/composables/vue-query-with-magic'
import type { RouterInput, RouterOutput } from '@/types'

export type NotificationSetting = RouterOutput['notificationSettings']['list'][0]
export type EditNotificationSettingInputType = RouterInput['notificationSettings']['modify']

export function usePreferences() {
    return {
        list() {
            return useQuery({
                queryKey: ['notificationSettings'],
                queryFn: () => client.notificationSettings.list.query(),
                queries: ['NotificationsSettings'],
            })
        },
        edit() {
            return useMutation({
                mutationFn: (input: EditNotificationSettingInputType) =>
                    client.notificationSettings.modify.mutate(input),
                mutates: ['NotificationsSettings'],
            })
        },
    }
}

export function useActivity() {
    return {
        getUserLogs() {
            return useQuery({
                queryKey: ['userActivityLogs'],
                queryFn: () => client.userProfile.activityLogs.query(),
                queries: [],
            })
        },
    }
}

export function useCurrentAppVersion() {
    return {
        getVersion() {
            return useQuery({
                queryKey: ['version'],
                queryFn: () => client.versionAppCheck.getVersion.query(),
                queries: [],
                refetchInterval: 5 * 60 * 1000, // 5 minutes
            })
        },
    }
}
