<script lang="ts" setup>
import { computed, useSlots } from 'vue'
import BaseIcon from '../BaseIcon.vue'
import type { PassThrough } from 'primevue/ts-helpers'
import type { ButtonPassThroughOptions } from 'primevue/button'
import type { Size } from '@/composables/sizing'

export type LumoButtonType = 'plain' | 'lumo'

export interface LumoButtonProps {
    label?: string
    type?: LumoButtonType
    size?: Size
    disabled?: boolean
    active?: boolean
    loading?: boolean
    loadingLabel?: string
    icon?: string
    showIcon?: boolean
}

const props = withDefaults(defineProps<LumoButtonProps>(), {
    type: 'plain',
    size: 'md',
    disabled: false,
    active: false,
    loading: false,
    icon: 'sparkles',
    showIcon: true,
})

const slots = useSlots()
const buttonPT = computed<PassThrough<ButtonPassThroughOptions>>(() => ({
    root: {
        class: [
            'max-w-full z-[300] relative',
            'transition-all duration-200 ease-in-out',
            'flex shrink-0 items-center gap-1 rounded outline-none whitespace-nowrap text-primarySky-900 ',
            // Types
            {
                'bg-gradient-to-r from-teal-50 to-primarySky-50': props.type === 'lumo',
                'bg-white': props.type === 'plain',
            },
            // States
            {
                'bg-gradient-to-r from-teal-100 to-primarySky-100': props.active && !props.disabled,
                'cursor-pointer hover:bg-gradient-to-r hover:from-teal-100 hover:to-primarySky-100': !props.disabled,
                'cursor-default': props.disabled,
            },
            // Sizes
            {
                'px-2 py-[1px] text-xsmall-medium ': props.size === 'sm' && (props.label || slots?.default),
                'px-2 py-[3px] text-small-medium': props.size === 'md' && (props.label || slots?.default),
                'px-2 py-[1px] text-base-medium': props.size === 'lg' && (props.label || slots?.default),
            },
            // Icon only
            {
                'justify-center size-3': !props.label && !slots?.default && props.size === 'sm',
                'justify-center size-7': !props.label && !slots?.default && props.size === 'md',
                'justify-center size-8': !props.label && !slots?.default && props.size === 'lg',
            },
        ],
    },
}))
const emit = defineEmits<{ (name: 'click', event: Event): void }>()

const onClick = (event: Event) => {
    if (!props.disabled) {
        emit('click', event)
    }
}

const stars = [
    {
        left: '50px',
        top: '-7px',
        animationDelay: '0',
        animationName: 'ClockWise',
        class: 'star fill-yellow-500',
        size: 7,
    },
    {
        left: '-48px',
        top: '-52px',
        animationDelay: '0',
        animationName: 'CounterClockWise',
        class: 'star fill-yellow-500',
        size: 7,
    },
    {
        left: '12px',
        top: '-60px',
        animationDelay: '-2s',
        animationName: 'CounterClockWise',
        class: 'star fill-fuchsia-500',
        size: 11,
    },
    {
        left: '-75px',
        top: '-24px',
        animationDelay: '0s',
        animationName: 'ClockWise',
        class: 'star fill-fuchsia-500',
        size: 11,
    },
    {
        left: '0',
        top: '-55px',
        animationDelay: '-3.15s',
        animationName: 'CounterClockWise',
        class: 'star fill-slate-50',
        size: 7,
    },
]
</script>

<template>
    <div class="lumo-button relative w-fit p-[1px] z-[100]" :class="{ 'opacity-60': disabled }">
        <div class="background bg-gradient-to-r from-teal-300 via-primarySky-300 to-teal-300 rounded z-[300]" />
        <PButton unstyled :pt="buttonPT" @click="onClick">
            <BaseIcon v-if="showIcon" :icon="icon" :size="size === 'sm' ? size : 'md'" />
            <slot>
                <span v-if="loadingLabel && loading" class="px-1">{{ loadingLabel }}</span>
                <span v-else-if="label" class="px-1">{{ label }}</span>
            </slot>
        </PButton>

        <div v-if="loading" class="star-wrapper">
            <svg
                v-for="(star, index) in stars"
                :key="index"
                :class="star.class"
                :style="{
                    left: star.left,
                    top: star.top,
                    animationDelay: star.animationDelay,
                    animationName: star.animationName,
                }"
                :width="star.size"
                :height="star.size"
                viewBox="0 0 116 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.70909 63.1102C-0.919555 60.9593 -0.919553 54.3787 3.70909 52.2278L34.8459 37.7586C36.1301 37.1618 37.1618 36.1301 37.7586 34.8459L52.2278 3.70907C54.3788 -0.919574 60.9593 -0.919571 63.1102 3.70908L77.5795 34.8459C78.1762 36.1301 79.2079 37.1618 80.4921 37.7586L111.629 52.2278C116.258 54.3787 116.258 60.9593 111.629 63.1102L80.4921 77.5794C79.2079 78.1762 78.1762 79.2079 77.5795 80.4921L63.1102 111.629C60.9593 116.258 54.3788 116.258 52.2278 111.629L37.7586 80.4921C37.1618 79.2079 36.1301 78.1762 34.8459 77.5794L3.70909 63.1102Z"
                />
            </svg>
        </div>

        <div v-if="loading" class="particle-wrapper">
            <div
                v-for="i in 4"
                :key="i"
                class="particle"
                :style="{
                    rotate: Math.random() * 180 + 100 + 'deg',
                    animationDelay: Math.random() * -1 * 4 + 's',
                    left: Math.random() * 200 - 100 + 'px',
                    top: Math.random() * 50 - 25 + 'px',
                }"
            />
        </div>
    </div>
</template>

<style lang="scss">
.lumo-button {
    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: 600% 600%;
        animation: AnimateBorder 4s ease infinite;
    }

    .star-wrapper {
        scale: 0.7;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        svg {
            position: absolute;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            transform-origin: 40px 40px;
        }
    }

    .particle-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        .particle {
            position: absolute;
            width: 4px;
            height: 2px;
            background: var(--teal-200);
            border-radius: 50%;
            // https://svg-path-visualizer.netlify.app/#M0%2C0%20C0%2C1%201%2C1%201%2C0%20C1%2C-1%200%2C-1%200%2C0%20C0%2C1%20-1%2C1%20-1%2C0%20C-1%2C-1%200%2C-1%200%2C0
            offset-path: path('M0,0 C0,50 50,50 50,0 C50,-50 0,-50 0,0 C0,50 -50,50 -50,0 C-50,-50 0,-50 0,0');
            transform-origin: 1px 1px;
            animation: Move 6000ms infinite linear;
        }
    }

    @keyframes ClockWise {
        0% {
            transform: rotate(0deg) scale(1);
        }
        25% {
            transform: rotate(90deg) scale(1.5);
        }
        50% {
            transform: rotate(180deg) scale(1);
        }
        75% {
            transform: rotate(270deg) scale(1.5);
        }
        100% {
            transform: rotate(360deg) scale(1);
        }
    }
    @keyframes CounterClockWise {
        0% {
            transform: rotate(0deg) scale(1);
        }
        25% {
            transform: rotate(-90deg) scale(1.5);
        }
        50% {
            transform: rotate(-180deg) scale(1);
        }
        75% {
            transform: rotate(-270deg) scale(1.5);
        }
        100% {
            transform: rotate(-360deg) scale(1);
        }
    }
    @keyframes AnimateBorder {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @keyframes Move {
        0% {
            offset-distance: 0%;
        }
        100% {
            offset-distance: 100%;
        }
    }
}
</style>
