<script setup lang="ts">
import Divider from 'primevue/divider'
import { computed } from 'vue'

const props = withDefaults(
    defineProps<{
        color?: 'light' | 'dark'
        layout?: 'horizontal' | 'vertical'
    }>(),
    {
        color: 'light',
        layout: 'horizontal',
    }
)

const borderClass = computed(() => (props.color === 'light' ? 'before:border-slate-200 ' : 'before:border-slate-300 '))
const paddingClass = computed(() => (props.layout === 'horizontal' ? 'my-2' : 'mx-2'))
</script>

<template>
    <Divider :pt="{ root: { class: [borderClass, paddingClass] } }" :layout="layout">
        <slot />
    </Divider>
</template>
