import { type RouteLocationNormalized } from 'vue-router'
import { createAuthGuard } from '@auth0/auth0-vue'
import { type App } from 'vue'

export function getAuthGuard(app: App) {
    const authGuard = createAuthGuard(app)
    return function (to: RouteLocationNormalized) {
        const authRequiredInRouteOrParent = to.matched.map((m) => m.meta.requiresAuth).includes(true)
        if (authRequiredInRouteOrParent) return authGuard(to)
    }
}
