<script lang="ts" setup>
import { computed } from 'vue'
import { DetailAttributeTypeEnum } from '@prisma/client'

import { useSelector, type SelectorItem } from '@/composables/selector'

import BaseIcon from './BaseIcon.vue'
import DButton, { type DButtonType } from './DButton.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

const {
    UNSELECT_ITEM: { id: NOT_SELECTED },
} = useSelector()

const props = withDefaults(
    defineProps<{
        modelValue?: string[]
        detailAttribute: {
            values: {
                id: string
                label: string
            }[]
            id: string
            label: string
            type: DetailAttributeTypeEnum
        }
        filterPlaceholder?: string
        buttonType?: DButtonType
        testid?: string
    }>(),
    {
        testid: 'd-selector-detailAttributes',
        buttonType: 'ghost',
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value?: string[]): void
    (name: 'select', value?: string): void
    (name: 'unselect'): void
}>()

const selected = computed(() => props.detailAttribute.values.filter((item) => props.modelValue?.includes(item.id)))
const showPlaceholder = computed(() => !props.modelValue?.length)

const onModelUpdate = (items: SelectorItem[]) => {
    if (!items) return
    emit('update:modelValue', items[0]?.id === NOT_SELECTED ? undefined : items.map((item) => item.id))
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="detailAttribute.values"
        max-width="sm"
        :unselect-item-label="$t('notSet')"
        :multiple="detailAttribute.type === DetailAttributeTypeEnum.multi_select"
        :filter-placeholder="filterPlaceholder"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect')"
        @update:model-value="onModelUpdate"
    >
        <DButton :type="buttonType ?? 'ghost'" :data-testid="testid">
            <template v-if="showPlaceholder">
                <BaseIcon icon="ItemDetailProperty" class="text-slate-500" size="md" />
                {{ detailAttribute.label }}: {{ $t('notSet') }}
            </template>
            <template v-else>
                <BaseIcon icon="ItemDetailProperty" size="md" />
                {{
                    selected.length > 1
                        ? $t('filters.properties.itemDetailProperty', { count: selected.length })
                        : selected[0]?.label
                }}
            </template>
        </DButton>
    </DSelectorGeneric>
</template>
