import { client } from '@/plugins/trpc'
import { useQuery, useMutation } from '@/composables/vue-query-with-magic'
import type { RouterInput, RouterOutput } from '@/types'

export type NotificationItem = RouterOutput['notifications']['list'][0]
export type NotificationRemindInput = RouterInput['notifications']['remind']

export function useNotificationsInbox() {
    return {
        list() {
            // Refetch inbox list every 5 sec
            return useQuery({
                queryKey: ['notificationsList'],
                queryFn: () => client.notifications.list.query(),
                queries: ['Notification'],
                refetchInterval: 5000,
                initialData: [],
            })
        },
        markAllRead() {
            return useMutation({
                mutationFn: () => client.notifications.markAllReadUnread.mutate({ isRead: true }),
                mutates: ['Notification'],
            })
        },
        markAllUnread() {
            return useMutation({
                mutationFn: () => client.notifications.markAllReadUnread.mutate({ isRead: false }),
                mutates: ['Notification'],
            })
        },
        markRead() {
            return useMutation({
                mutationFn: (ids: string[]) => client.notifications.markReadUnread.mutate({ ids, isRead: true }),
                mutates: ['Notification'],
            })
        },
        markUnread() {
            return useMutation({
                mutationFn: (ids: string[]) => client.notifications.markReadUnread.mutate({ ids, isRead: false }),
                mutates: ['Notification'],
            })
        },
        deleteAll() {
            return useMutation({
                mutationFn: () => client.notifications.deleteAll.mutate(),
                mutates: ['Notification'],
            })
        },
        deleteRead() {
            return useMutation({
                mutationFn: () => client.notifications.deleteRead.mutate(),
                mutates: ['Notification'],
            })
        },
        deleteByIds() {
            return useMutation({
                mutationFn: (ids: string[]) => client.notifications.deleteByIds.mutate({ ids }),
                mutates: ['Notification'],
            })
        },
        remind() {
            return useMutation({
                mutationFn: (input: NotificationRemindInput) => client.notifications.remind.mutate(input),
                mutates: ['Notification'],
            })
        },
    }
}
