<script setup lang="ts">
import { ref, watch } from 'vue'
import { useCurrentAppVersion } from '@/modules/auth/queries'
import DInlineMessage from '@/components/DInlineMessage.vue'

const showUpdateNotification = ref(false)
const { getVersion } = useCurrentAppVersion()
const { data: latestVersion } = getVersion()

const checkVersion = () => {
    const envVersion = window.env.VERSION_TAG

    if (envVersion !== latestVersion?.value) {
        showUpdateNotification.value = true
    }
}

watch(latestVersion, () => {
    checkVersion()
})

const refreshPage = () => {
    location.reload()
}
</script>

<template>
    <DInlineMessage
        v-if="showUpdateNotification"
        class="shadow-md top-5 right-5 !fixed !w-96 z-tooltip"
        type="info"
        :text="$t('info')"
        :message="$t('newVersionAvailable')"
        :button="{ text: $t('refresh'), command: refreshPage }"
    />
</template>
