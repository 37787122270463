import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'

export const useBusinessGoals = () => {
    const {
        data: businessGoals,
        isFetching,
        isLoading,
        isRefetching,
    } = useQuery({
        queryKey: ['listAllBusinessGoals'],
        queryFn: () => client.businessGoal.list.query(),
        initialData: [],
        queries: ['BusinessGoal'],
    })

    return {
        businessGoals,
        isFetching,
        isLoading,
        isRefetching,
    }
}
