import { type RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'

export function roleGuard(to: RouteLocationNormalized) {
    const authRequiredInRouteOrParent = to.matched.map((m) => m.meta.requiresAuth).includes(true)
    if (!authRequiredInRouteOrParent) return

    const authStore = useAuthStore()
    const userRoles = authStore.userRoles
    const routeRoles = to.matched.flatMap((m) => m.meta.roles || [])
    const hasAllRoles = routeRoles.every((role) => userRoles.includes(role))

    if (!hasAllRoles) {
        return { name: 'access-denied' }
    }
}
