<script lang="ts" setup>
import DInlineMicroForm, { type InlineMicroForm } from '@/components/DInlineMicroForm.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import DAlertDialog from '@/components/DAlertDialog.vue'
import { ref } from 'vue'
import { URL_MAX_LENGTH, DELIGHT_DOMAIN_URL } from '@/constants'

const emit = defineEmits<{
    (name: 'submit', value: InlineMicroForm): void
    (name: 'reset'): void
}>()

const href = ref<string>()
const overlayPanel = ref()
const DIALOG_LINK_MAX_LENGTH = 60

let isDialogVisible = ref(false)
const continueToExternalSite = () => {
    window.open(href.value, 'blank')
    isDialogVisible.value = false
}

const showDialogForExternalDomains = (e: Event) => {
    const currentDomain = window.location.hostname
    const urlDomain = new URL(href.value as string).hostname
    if (currentDomain === urlDomain || urlDomain.match(DELIGHT_DOMAIN_URL)) return
    e.preventDefault()
    isDialogVisible.value = true
}

const toggle = (event: Event) => {
    overlayPanel.value.toggle(event)
}

const show = (event: Event, domNode?: { href: string }) => {
    href.value = domNode?.href
    overlayPanel.value.show(event)
}
const hide = () => {
    overlayPanel.value.hide()
}

defineExpose({ toggle, show, hide })
</script>

<template>
    <POverlayPanel ref="overlayPanel">
        <DInlineMicroForm
            id="textEditor.link"
            class="texteditor-link-panel p-2"
            focus-on-mounted
            :max-length="URL_MAX_LENGTH"
            :text="href"
            :placeholder="$t('textEditor.linkPanel.placeholder')"
            reset-button-icon="trash"
            :reset-button-tooltip-text="$t('textEditor.linkPanel.removeLink')"
            @submit="emit('submit', $event)"
            @reset="emit('reset')"
        >
            <template #buttons>
                <a
                    v-if="href"
                    v-tooltip.bottom="$t('textEditor.linkPanel.openLink')"
                    class="inline-flex size-6 shrink-0 cursor-pointer items-center justify-center gap-1 rounded bg-transparent p-1 text-slate-500 outline-none transition-all duration-200 ease-in-out hover:bg-slate-100"
                    :href="href"
                    target="_blank"
                    @click="showDialogForExternalDomains"
                >
                    <BaseIcon icon="external-link" size="md" />
                </a>
            </template>
        </DInlineMicroForm>
    </POverlayPanel>

    <DAlertDialog
        v-model:visible="isDialogVisible"
        type="warn"
        modal
        :header="$t('textEditor.redirectDialog.header')"
        :confirm-button-label="$t('textEditor.redirectDialog.confirm')"
        :cancel-button-label="$t('textEditor.redirectDialog.cancel')"
        container-class="relative bottom-44"
        @cancel="isDialogVisible = false"
        @confirm="continueToExternalSite"
    >
        <template #content>
            <i18n-t
                keypath="textEditor.redirectDialog.message"
                tag="div"
                class="text-small max-h-80 break-words text-slate-500"
            >
                <template #url>
                    <span class="text-primarySky-700">
                        {{
                            href?.length && href.length > DIALOG_LINK_MAX_LENGTH
                                ? `${href.substring(0, DIALOG_LINK_MAX_LENGTH)}...`
                                : href
                        }}
                    </span>
                </template>
            </i18n-t>
        </template>
    </DAlertDialog>
</template>
