import { watch, type App } from 'vue'

declare const window: Window &
    typeof globalThis & {
        heap: HeapAnalytics
    }

class HeapEventQueue {
    items: { event: string; properties?: object }[] = []

    enqueue(element: { event: string; properties?: object }) {
        this.items.push(element)
    }

    dequeue() {
        return this.items.shift()
    }

    size() {
        return this.items.length
    }
}

interface HeapAnalytics {
    track: (event: string, properties?: object) => void
    identify: (identity: string) => void
    resetIdentity: () => void
    addUserProperties: (properties: object) => void
    addEventProperties: (properties: object) => void
    removeEventProperty: (property: string) => void
    clearEventProperties: () => void
    appid: string
    userId: string
    identity: string | null
    config: unknown
    loaded: boolean
}

async function waitForHeapOrThrow(maxRetries = 5): Promise<boolean> {
    let retries = 0

    async function tryCheck(): Promise<boolean> {
        if (window.heap.loaded) {
            return true
        } else if (retries < maxRetries) {
            retries++
            await new Promise((resolve) => setTimeout(resolve, 1000)) // 1-second delay
            return tryCheck() // Retry the check
        } else {
            // Max retries exceeded
            throw Error('Heap Analytics could not be loaded')
        }
    }

    return tryCheck()
}

let heap: HeapAnalytics | undefined

const heapQueue = new HeapEventQueue()

// plugins/heap.js
export default {
    // inject a globally available $heap() method
    // If the user is authenticated, we want to inform Heap Analytics about the user
    install: async (app: App) => {
        await waitForHeapOrThrow()
        heap = window.heap as HeapAnalytics

        watch(app.config.globalProperties.$auth0.user, (user) => {
            if (!user || !heap) return

            // added explicit check because calling the API with 'undefined' can cause a merge of unidentified users
            // sub is equal to user ID in Auth0 and cannot be changed
            if (user.sub) {
                heap.identify(user.sub)
            }

            const AUTH0_NAMESPACE = window.env.AUTH0_NAMESPACE

            heap.addUserProperties({
                userAuth0Id: user.sub,
                orgId: user.org_id,
                orgName: user[`${AUTH0_NAMESPACE}/org_name`],
                email: user.email,
                name: user.name,
                companyRole: user.companyRole,
                roles: user[`${AUTH0_NAMESPACE}/roles`],
            })
        })

        app.config.globalProperties.$heap = heap
        app.provide('heap', app.config.globalProperties.$heap)
    },
}

export const useHeap = () => {
    return {
        track(event: string, properties?: object) {
            if (heap) {
                // First check if items are queued and send them
                if (heapQueue.size()) {
                    let queuedEvent = heapQueue.dequeue()
                    while (queuedEvent) {
                        heap.track(queuedEvent.event, queuedEvent.properties)
                        queuedEvent = heapQueue.dequeue()
                    }
                }
                heap.track(event, properties)
            } else {
                heapQueue.enqueue({ event, properties })
            }
        },
    }
}

declare module 'vue' {
    interface ComponentCustomProperties {
        $heap: HeapAnalytics
    }
}
