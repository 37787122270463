import { FeatureToggle } from '@mindfuel/server/src/common/config'
import { client } from '@/plugins/trpc'
import { computed } from 'vue'
import { useQuery } from '@/composables/vue-query-with-magic'

export const useFeatureToggles = () => {
    const { isPending, data } = useQuery({
        queryKey: ['featureToggles'],
        queryFn: () => client.featureToggle.list.query(),
        queries: ['FeatureToggle'],
        staleTime: 1000 * 60 * 5,
        initialData: [],
        initialDataUpdatedAt: 0,
    })

    return {
        list: data,
        isPending,
        ...Object.values(FeatureToggle).reduce(
            (acc, feature) => {
                acc[feature] = computed(() => data.value.includes(feature))
                return acc
            },
            {} as Record<FeatureToggle, ReturnType<typeof computed<boolean>>>
        ),
    }
}
