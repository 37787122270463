import { useI18n } from 'vue-i18n'
import { CompanyRoleEnum, OpportunityPriorityEnum, ProductPriorityEnum } from '@prisma/client'
import type { PriorityEnumUnion } from './data/priority'
import { entityTypeInfo } from './entityTypeMapping'

export type SelectorItem = {
    id: string
    label: string
    icon?: string
    avatar?: {
        id?: string
        photoUrl?: string | null
    }
    disabled?: boolean
    disabledText?: string
    iconColor?: string
    labelColor?: string
    tagLabelColor?: string
    bgColor?: string
    hoverColor?: string
    children?: SelectorItem[]
}

export const EntityTypeEnumRefactored = {
    Opportunity: 'Opportunity',
    DataProduct: 'DataProduct',
} as const

export type EntityTypeEnumRefactored = (typeof EntityTypeEnumRefactored)[keyof typeof EntityTypeEnumRefactored]

export const useSelector = () => {
    const UNSELECT_ITEM = { id: 'not-selected', label: '-', icon: 'line-dashed' } as const

    return { UNSELECT_ITEM }
}

export const useSelectorEntityType = () => {
    const { t } = useI18n()

    const entityTypeSelectorItems: SelectorItem[] = [
        {
            id: EntityTypeEnumRefactored.Opportunity,
            label: t(`components.SelectorEntityType.${EntityTypeEnumRefactored.Opportunity}`),
            icon: entityTypeInfo[EntityTypeEnumRefactored.Opportunity].icon,
        },
        {
            id: EntityTypeEnumRefactored.DataProduct,
            label: t(`components.SelectorEntityType.DataProduct`),
            icon: 'data-product-type',
        },
    ] as const

    return { entityTypeSelectorItems }
}

export const useDropdownCompanyRole = () => {
    const { t } = useI18n()

    const companyRoleEnumKeys: CompanyRoleEnum[] = Object.keys(CompanyRoleEnum) as CompanyRoleEnum[]
    const dropdownItems: SelectorItem[] = companyRoleEnumKeys.map((key) => {
        return {
            id: key,
            label: t(`components.Selector.companyRole.${key}`),
        }
    })

    return { dropdownItems }
}

export const useIcons = () => {
    const priorityIcons: Record<PriorityEnumUnion, { icon: string; color: string }> = {
        [OpportunityPriorityEnum.high]: {
            icon: 'priority-high',
            color: '',
        },
        [OpportunityPriorityEnum.medium]: {
            icon: 'priority-medium',
            color: '',
        },
        [OpportunityPriorityEnum.low]: {
            icon: 'priority-low',
            color: '',
        },
        [ProductPriorityEnum.now]: {
            icon: 'priority-high',
            color: '',
        },
        [ProductPriorityEnum.next]: {
            icon: 'priority-medium',
            color: '',
        },
        [ProductPriorityEnum.later]: {
            icon: 'priority-low',
            color: '',
        },
        [OpportunityPriorityEnum.not_set]: {
            icon: 'priority-not-set',
            color: 'text-slate-500',
        },
    }

    return { priorityIcons }
}
