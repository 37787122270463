import type { RemoteConfig } from '@mindfuel/server/src/common/types'

export default async function loadRemoteConfig() {
    //nothing to do here config is injected into window
}

declare global {
    interface Window {
        env: RemoteConfig
    }
}
