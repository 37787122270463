import type { ProductPriorityEnum } from '@prisma/client'

export type ValueScoreScale = 'very_low' | 'low' | 'medium' | 'high' | 'very_high' | 'not_set'

type EnumColumnType = ProductPriorityEnum | ValueScoreScale
type ColorDef = {
    bg: string
    icon?: string
    text: string
}

export const usePredefinedColors = (type: string) => {
    const enumColors: Record<EnumColumnType, ColorDef> = {
        // ProductPriorityEnum
        now: {
            bg: 'bg-green-100',
            text: 'text-green-900',
        },
        next: {
            bg: 'bg-orange-50',
            text: 'text-orange-600',
        },
        later: {
            bg: 'bg-bluegray-200',
            text: 'text-bluegray-600',
        },
        not_set: {
            bg: 'bg-bluegray-50',
            icon: 'text-bluegray-400',
            text: 'text-bluegray-600',
        },

        /* Value */
        very_low: { bg: 'bg-red-100', icon: 'text-red-400', text: 'text-red-800' },
        low: { bg: 'bg-yellow-100', icon: 'text-yellow-500', text: 'text-yellow-800' },
        medium: { bg: 'bg-cyan-100', icon: 'text-cyan-400', text: 'text-cyan-800' },
        high: { bg: 'bg-green-100', icon: 'text-green-600', text: 'text-green-800' },
        very_high: { bg: 'bg-green-100', icon: 'text-yellow-300', text: 'text-green-800' },
    }

    const colors: Record<string, ColorDef> = {
        /* Menu Sidebar */
        'mission-control': {
            bg: 'bg-purple-100',
            text: 'text-purple-800',
        },
        opportunities: {
            bg: 'bg-yellow-100',
            text: 'text-yellow-800',
        },
        'smart-products': {
            bg: 'bg-fuchsia-300',
            text: 'text-fuchsia-800',
        },
        'analytics-products': {
            bg: 'bg-indigo-100',
            text: 'text-indigo-800',
        },
        'data-assets': {
            bg: 'bg-green-200',
            text: 'text-green-800',
        },
        'tech-products': {
            bg: 'bg-primarySky-300',
            text: 'text-primarySky-800',
        },
        sources: {
            bg: 'bg-blue-100',
            text: 'text-blue-800',
        },
        settings: {
            bg: 'bg-gray-300',
            text: 'text-gray-800',
        },
    }

    return enumColors[type as EnumColumnType] || colors[type] || { bg: '', icon: '', text: '' }
}

export const useTextAndBgColorClass = (type: string) => {
    const { bg, text } = usePredefinedColors(type)
    return `${bg} ${text}`
}
