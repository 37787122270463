<script lang="ts" setup>
/**
 * This component utilises VueQuill v1.2.0.
 */
import { computed, ref, type Ref, watch } from 'vue'
import { QuillEditor, Quill } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import 'quill-paste-smart'

import { onClickOutside } from '@vueuse/core'
import { showMentionPopup } from '@/composables/quillMention'
import DTextEditorToolbar from './DTextEditorToolbar.vue'
import DTextEditorFooter from './DTextEditorFooter.vue'
import DTextEditorLinkPanel from './DTextEditorLinkPanel.vue'
import DButton from './DButton.vue'

import { useHeap } from '@/plugins/heap'

import {
    type TextEditorContent,
    type TextEditorProps,
    type RangeStatic,
    type TextEditorChangeEvent,
    useTextEditorMethods,
    registerModules,
    useLinks,
    LINE_HEIGHT,
    EDITOR_PADDING,
    COLLAPSED_MAX_ROWS,
} from '@/composables/textEditor'
import { CustomLinkSanitizer } from '@/composables/textEditor'

const props = withDefaults(defineProps<TextEditorProps>(), {
    value: '',
    placeholder: '',
    size: 'md',
    disabledHeadings: false,
    minRows: 2,
    readonly: false,
    editorId: '',
    autoFocus: false,
    tabIndex: 0,
    saveOnBlur: true,
    theme: 'bubble',
    collapsed: false,
    expandButtonLabel: '',
    collapseButtonLabel: '',
    boundsValue: '',
    excludeFormats: () => [],
})

const quillInstance: Ref<typeof QuillEditor | undefined> = ref()
const linkPanel = ref<typeof DTextEditorLinkPanel | undefined>()
const selection = ref<{ index: number; length: number } | undefined>(undefined)
const textContentLength = ref(0)
const selectionFormat = ref<string>('')

const id = computed(() => `d-text-editor-bubble-toolbar-${props.editorId}`)

const emit = defineEmits(['text-change', 'cancel-change', 'change']) as {
    (event: 'text-change', value: TextEditorContent): void
    (event: 'cancel-change', value: TextEditorContent): void
    (event: 'change', value: TextEditorChangeEvent): void
}

const {
    onSave,
    onCancel,
    getText,
    getLastIndex,
    insertLineBreak,
    insertText,
    replaceWithHTML,
    onTextChange,
    deltaValue,
    setFormat,
    getContentLength,
} = useTextEditorMethods(quillInstance, props, emit, selectionFormat)

const { editLink, linkSubmit, linkReset } = useLinks(quillInstance, linkPanel, selection)

const { modules } = registerModules({ editMode: ref(true), linkPanel })

const isCollapsed = ref(props.collapsed ?? false)

// NOTE: quills placeholder is not updating dynamically: https://github.com/slab/quill/issues/1150
watch(
    () => props.placeholder,
    (value) => {
        const quill = quillInstance.value?.getQuill()
        quill.root.setAttribute('data-placeholder', value)
    }
)

const setEditorMaxHeight = (value?: string) => {
    const quill = quillInstance.value?.getQuill()
    if (!quill) return

    if (!value) {
        quill.root.style.maxHeight = LINE_HEIGHT * COLLAPSED_MAX_ROWS + EDITOR_PADDING + 'rem'
    } else {
        quill.root.style.maxHeight = value === 'none' ? 'none' : value + 'rem'
    }
}

const onEditorCreated = (quill: Quill) => {
    const maxRows = props.collapsed ? COLLAPSED_MAX_ROWS : props.maxRows

    quill.root.style.minHeight = LINE_HEIGHT * props.minRows + EDITOR_PADDING + 'rem'
    quill.root.style.maxHeight = maxRows ? LINE_HEIGHT * maxRows + EDITOR_PADDING + 'rem' : 'none'
    quill.root.tabIndex = props.tabIndex
    if (props.autoFocus && !props.readonly) {
        quillInstance.value?.focus()

        const length = quill.getLength()
        quill.setSelection(length, 0)
    }
    textContentLength.value = getContentLength()
}

const textEditorBubble = ref(null)
const canSaveOnClickOutside = ref(false)
onClickOutside(
    textEditorBubble,
    () => {
        if (canSaveOnClickOutside.value) {
            if (!props.saveOnBlur || (props.maxCharacters && textContentLength.value > props.maxCharacters)) return
            onSave()
        }
        canSaveOnClickOutside.value = false
        if (props.collapsed) {
            isCollapsed.value = true
            setEditorMaxHeight()
        }
    },
    { ignore: ['.texteditor-dropdown-menu', '.texteditor-link-panel', '.d-alert-dialog', '.d-alert-dialog-mask'] }
)

const onTextChangeHandler = () => {
    textContentLength.value = getContentLength()
    onTextChange()
}

const getTextFormatOnSelection = (event: { range: RangeStatic; oldRange: RangeStatic; source: string }) => {
    selectionFormat.value = ''
    if (event.source !== 'user') return
    const quill = quillInstance.value?.getQuill()

    const [link, offset] = quill.scroll.descendant(CustomLinkSanitizer, event.range?.index)
    if (link?.domNode) {
        selection.value = { index: event.range?.index - offset, length: link.length() }
        selectionFormat.value = 'link'
        linkPanel.value?.show({ currentTarget: link.domNode }, link.domNode)
        return
    }

    if (!event.range?.length || event.range?.index < 0) return

    selection.value = event.range
    const formats = quill?.getFormat()
    const headerValue = formats?.header
    if (headerValue) {
        selectionFormat.value = 'heading' + headerValue
        return
    }

    selectionFormat.value = 'text'
}

const { track } = useHeap()

const expandContent = () => {
    if (!props.collapsed) return
    isCollapsed.value = false
    setEditorMaxHeight('none')
    track('Click Expand Text Editor')
}

const collapseContent = () => {
    if (!props.collapsed) return
    isCollapsed.value = true
    setEditorMaxHeight()
    track('Click Collapse Text Editor')
}

const onFocus = () => {
    canSaveOnClickOutside.value = true
    isCollapsed.value = false

    if (!props.collapsed) return
    setEditorMaxHeight('none')
}

const isContentMoreThanMaxRows = () => {
    if (!props.collapsed) return false
    const quill = quillInstance.value?.getQuill()
    if (!quill) return false

    const editor = quill.root
    const lineHeight = parseInt(getComputedStyle(editor).lineHeight)
    const numberOfLines = Math.round(editor.offsetHeight / lineHeight)

    return numberOfLines > COLLAPSED_MAX_ROWS
}

const textSizeClasses = computed(() => ({
    'base-text': props.size === 'md',
    'small-text': props.size === 'sm',
}))

defineExpose({
    getText,
    getLastIndex,
    insertLineBreak,
    insertText,
    replaceWithHTML,
    save: onSave,
    textContentLength,
    expandContent,
    collapseContent,
})
</script>

<template>
    <div ref="textEditorBubble" class="relative w-full" :class="{ 'mb-8': collapsed && isContentMoreThanMaxRows() }">
        <DTextEditorToolbar
            class="bubble-toolbar"
            :editor-id="id"
            :total-chars="textContentLength"
            :max-chars="maxCharacters"
            :excluded-formats="[...excludeFormats, 'clean']"
            :quill-instance="quillInstance?.getQuill()"
            :selection-format="selectionFormat"
            :disabled-headings="disabledHeadings"
            @set-format="setFormat"
            @mention-click="showMentionPopup(quillInstance?.getQuill(), $event)"
            @edit-link="editLink"
        />

        <div>
            <QuillEditor
                ref="quillInstance"
                :toolbar="`#${id}`"
                :class="[[ghost ? 'ghost' : 'show-border', { collapsed }, textSizeClasses], { footer: !saveOnBlur }]"
                :placeholder="placeholder"
                :read-only="readonly"
                theme="bubble"
                content-type="delta"
                :content="deltaValue"
                :modules="modules"
                :options="{ bounds: `[data-texteditor-bounds='${boundsValue}']` }"
                @focus="onFocus"
                @ready="onEditorCreated"
                @text-change="onTextChangeHandler"
                @selection-change="getTextFormatOnSelection"
            />

            <DTextEditorFooter
                v-if="!saveOnBlur"
                class="absolute bottom-[13px] right-1.5"
                :max-characters="maxCharacters"
                :text-content-length="textContentLength"
                :has-cancel-button="hasCancelButton"
                :save-button-label="saveButtonLabel"
                :save-button-icon="saveButtonIcon"
                @save-change="onSave"
                @cancel-change="onCancel"
            />
        </div>

        <div v-if="collapsed && isContentMoreThanMaxRows()">
            <div
                v-if="isCollapsed"
                id="bottom-fade-overlay"
                class="oveflow-hidden absolute bottom-0 h-[3rem] bg-white/50 bg-gradient-to-b from-transparent to-white"
                :class="ghost ? 'left-0 right-0' : 'left-[1px] right-[1px] rounded-bl-md rounded-br-md'"
                @click="expandContent"
            />

            <DButton
                class="absolute mt-2"
                type="ghost"
                :icon="isCollapsed ? 'chevron-down' : 'chevron-up'"
                @click="isCollapsed ? expandContent() : collapseContent()"
            >
                {{
                    isCollapsed
                        ? expandButtonLabel || $t('textEditor.expand')
                        : collapseButtonLabel || $t('textEditor.collapse')
                }}
            </DButton>
        </div>

        <DTextEditorLinkPanel ref="linkPanel" @submit="linkSubmit" @reset="linkReset" />
    </div>
</template>

<style lang="css">
.ql-editor.ql-blank::before {
    @apply mb-2;
}

.ql-container.ql-bubble .ql-editor {
    p,
    ol,
    ul,
    pre,
    blockquote {
        @apply mb-2;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &:not(:first-child) {
            @apply mt-4;
        }
        @apply mb-2;
    }
}

.ql-container.ql-bubble .ql-editor {
    @apply font-sans break-normal;
}

.base-text.ql-container.ql-bubble .ql-editor {
    @apply text-base-normal;
}
.small-text.ql-container.ql-bubble .ql-editor {
    @apply text-sm;
}

.collapsed .ql-editor {
    @apply overflow-hidden;
}
/* Ghost Style */
.ghost.ql-container.ql-bubble .ql-editor {
    @apply p-0;
}
/* Placeholder */
.ghost.ql-container.ql-bubble > .ql-editor.ql-blank::before {
    @apply left-0 not-italic text-slate-400;
}
/* Border Styles  */
.show-border.ql-container.ql-bubble .ql-editor {
    @apply rounded-lg border border-slate-300 px-2 py-1.5;
}
.footer.ql-container.ql-bubble .ql-editor {
    @apply pb-5;
}
.show-border.ql-container.ql-bubble .ql-editor:focus-visible {
    @apply border-indigo-500;
}
/* Placeholder */
.show-border.ql-container.ql-bubble > .ql-editor.ql-blank::before {
    @apply left-2 not-italic text-slate-400;
}

/* Floating Toolbar */
.ql-bubble .ql-tooltip,
.ql-bubble .ql-tooltip.ql-editing[data-mode='link'] {
    @apply z-50 bg-transparent;
}

.ql-bubble .ql-tooltip-arrow {
    @apply hidden;
}

.bubble-toolbar,
.ql-bubble .ql-toolbar {
    @apply border-primarySky-100 shadow-elevation-medium relative flex items-center overflow-hidden rounded-lg border bg-white px-1.5;
}

.ql-bubble .bubble-toolbar button {
    @apply size-8 flex cursor-pointer items-center justify-center rounded text-slate-500 hover:bg-slate-100 hover:text-slate-500;
}
.ql-bubble .bubble-toolbar button.ql-active {
    @apply text-primarySky-500;
}

/* Headings 1 & 2 */
.ql-container.ql-bubble .ql-editor h1 {
    @apply text-xl font-semibold;
}
.ql-container.ql-bubble .ql-editor h2 {
    @apply text-lg font-medium;
}

/* Lists */
.ql-container.ql-bubble .ql-editor ul,
.ql-container.ql-bubble .ql-editor ol {
    @apply pl-1.5;
}

/**** Links ****/
.ql-bubble .ql-tooltip.ql-editing[data-mode='link'] input[type='text'] {
    @apply rounded-md;
}

.ql-bubble .ql-tooltip-editor input[type='text'] {
    @apply rounded-md;
}

.ql-bubble .ql-tooltip[data-mode='link'] input[type='text'] {
    @apply border-primarySky-500 shadow-elevation-medium z-50 ml-5 mt-10 border bg-white text-slate-500 caret-slate-500;
}

.ql-bubble .ql-tooltip.ql-editing[data-mode='link'] input[type='text']::placeholder {
    @apply not-italic text-slate-500;
}

.ql-container.ql-bubble .ql-editor a,
.mention[data-denotation-char='#'] a {
    @apply text-primarySky-700 cursor-pointer whitespace-break-spaces no-underline hover:underline hover:underline-offset-2;
}

/**** Hide default tooltip on link hover ****/
.ql-container.ql-bubble:not(.ql-disabled) a::before,
.ql-container.ql-bubble:not(.ql-disabled) a::after {
    @apply hidden;
}

/**
* The classes below fall into a "repeated utility classes" category for bubble theme.
* and can be moved to `_custom.scss`
*/

/**** Check list ****/
.ql-editor ul[data-checked] > li {
    @apply ml-2 flex items-center;
}

.ql-editor ul[data-checked] > li::before {
    @apply size-3 mr-1 mt-[0.09rem] inline-block cursor-pointer rounded-sm border bg-center bg-no-repeat text-slate-700 !content-['_'];
}

.ql-editor ul[data-checked='false'] > li::before {
    @apply bg-[url('/icons/circle.svg')];
}
.ql-editor ul[data-checked='true'] > li::before {
    @apply bg-[url('/icons/circle-check.svg')];
}

/**** Mention ****/
.ql-mention-list-container {
    @apply z-text-editor min-w-[216px] max-w-[600px] overflow-auto rounded-lg border-slate-700 bg-white shadow;
}

.ql-mention-loading {
    @apply px-5 py-0 leading-[44px];
}

.ql-mention-list {
    @apply m-0 list-none overflow-hidden !px-0 !py-1;
}

.ql-mention-list-item {
    @apply cursor-pointer p-2 font-normal leading-7;
}

.ql-mention-list-item-item {
    @apply flex items-center whitespace-nowrap px-2 text-sm text-slate-700;
}
.ql-mention-list-item-item .ql-list-item-text {
    @apply truncate;
}

.ql-mention-list-item-item.ql-no-list-item {
    @apply text-slate-500;
}

.ql-mention-list-item.disabled {
    cursor: auto;
}

.ql-mention-list-item.selected {
    background-color: #d3e1eb;
    text-decoration: none;
}

.mention {
    user-select: all;
}

.ql-container.ql-bubble .mention[data-denotation-char] {
    @apply text-primarySky-700 font-normal;
}

.ql-container.ql-bubble .mention[data-denotation-char='#'] .ql-mention-denotation-char {
    @apply inline;
}
</style>
