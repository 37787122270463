<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import DActionMenu, { type DMenuItem } from '../DActionMenu.vue'
import LumoButton from './LumoButton.vue'
import type { Size } from '@/composables/sizing'

const { t } = useI18n()

/**
 * NOTE: Currently we can't import props interface of other component in storybook
 * So we have to define the props here again. These are the same as in LumoButton.vue
 */
export type LumoButtonType = 'plain' | 'lumo'
export interface LumoButtonProps {
    label?: string
    type?: LumoButtonType
    size?: Size
    disabled?: boolean
    active?: boolean
    loading?: boolean
    loadingLabel?: string
    icon?: string
    showIcon?: boolean
}

const props = withDefaults(defineProps<LumoButtonProps>(), {
    type: 'plain',
    size: 'md',
    disabled: false,
    active: false,
    loading: false,
    icon: 'sparkles',
    showIcon: true,
})

const emit = defineEmits<{ (name: 'replace'): void; (name: 'append'): void }>()

const menuItems: DMenuItem[] = [
    {
        label: t('lumo.append'),
        selectMode: 'single',
        command: () => {
            emit('append')
        },
    },
    {
        label: t('lumo.replace'),
        selectMode: 'single',
        command: () => {
            emit('replace')
        },
    },
]
</script>
<template>
    <DActionMenu :menu-items="menuItems" :class="{ 'pointer-events-none': loading || disabled }">
        <LumoButton v-bind="props" />
    </DActionMenu>
</template>
