import { EntityTypeEnum } from '@prisma/client'
import { useI18n } from 'vue-i18n'

export const typeToPrefix: Record<EntityTypeEnum, string> = {
    [EntityTypeEnum.Opportunity]: 'op',
    [EntityTypeEnum.SmartProduct]: 'sp',
    [EntityTypeEnum.AnalyticsProduct]: 'ap',
    [EntityTypeEnum.DataAsset]: 'da',
    [EntityTypeEnum.TechProduct]: 'tp',
}

export const entityTypeInfo = {
    [EntityTypeEnum.Opportunity]: {
        icon: 'hexagonal-pyramid',
        iconColor: 'text-amber-700',
        borderColor: 'border-amber-200',
    },
    [EntityTypeEnum.AnalyticsProduct]: {
        icon: 'hexagonal-prism',
        iconColor: 'text-emerald-700',
        borderColor: 'border-emerald-200',
    },
    [EntityTypeEnum.DataAsset]: {
        icon: 'hexagon',
        iconColor: 'text-indigo-700',
        borderColor: 'border-indigo-200',
    },
    [EntityTypeEnum.TechProduct]: {
        icon: 'cpu',
        iconColor: 'text-primarySky-700',
        borderColor: 'border-primarySky-200',
    },
    [EntityTypeEnum.SmartProduct]: {
        icon: 'assembly',
        iconColor: 'text-fuchsia-700',
        borderColor: 'border-fuchsia-200',
    },
}

export const useEntityInfo = (type: EntityTypeEnum) => {
    return entityTypeInfo[type]
}

export function useEntityTypeMapping() {
    const { t } = useI18n()
    return {
        mapLabel(entityType: EntityTypeEnum) {
            return t(`entityTypes.${entityType}`)
        },
        mapIcon(entityType: EntityTypeEnum) {
            return entityTypeInfo[entityType]?.icon
        },
        mapIconColor(entityType: EntityTypeEnum) {
            return entityTypeInfo[entityType]?.iconColor
        },
    }
}
