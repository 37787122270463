<script lang="ts" setup>
import { ref, computed } from 'vue'

import DSideNav from '@/components/DSideNav.vue'
import SkeletonSideNav from '@/components/details-view/SkeletonSideNav.vue'

import { useAuthStore } from '@/stores/authStore'
import { useProfileQueries } from '@/queries/profile'
import { useNotificationsInbox, type NotificationItem } from '@/modules/inbox/queries'
import MarkerButton from '@/components/MarkerButton.vue'

const authStore = useAuthStore()
const { get: getCurrentUser } = useProfileQueries()

const { list: listAllNotifications } = useNotificationsInbox()
const { data: notificationsList } = listAllNotifications()
const unreadInboxCount = computed(
    () => notificationsList.value?.filter((item: NotificationItem) => !item.isRead).length
)

const { data: user, isPending: isUserLoading } = getCurrentUser()

const showNav = ref(true)
</script>
<template>
    <div id="default-layout" class="flex h-screen w-screen overflow-hidden">
        <SkeletonSideNav v-if="!user || isUserLoading" />

        <DSideNav
            v-else-if="showNav"
            class="flex-none"
            :user-id="user.id"
            :user="authStore.mappedUser"
            :unread-inbox-count="unreadInboxCount"
        />
        <div class="min-w-main-column-padded flex flex-col" :class="showNav ? 'w-full-minus-nav' : 'w-full'">
            <router-view @icon-left-toggle="showNav = !showNav" />
        </div>
        <MarkerButton v-if="!showNav" />
    </div>
</template>
