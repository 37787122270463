<script lang="ts" setup>
import { computed } from 'vue'
import { type SelectorItem } from '@/composables/selector'
import BaseIcon from './BaseIcon.vue'
import DButton, { type DButtonType } from './DButton.vue'
import { TeamTypeEnum } from '@prisma/client'
import DSelectorGeneric from './DSelectorGeneric.vue'
import DTags from './DTags.vue'

const props = defineProps<{
    modelValue?: string[]
    teams: { id: string; label: string }[]
    displayType: 'tags' | 'button'
    placeholder?: string
    filterPlaceholder?: string
    teamType?: TeamTypeEnum
    buttonType?: DButtonType
}>()

const emit = defineEmits<{
    (name: 'update:modelValue', value?: string[]): void
    (name: 'select', value: string): void
    (name: 'unselect', value: string): void
}>()

const selected = computed(() => props.teams.filter(({ id }) => props.modelValue?.includes(id)))

function onModelValueUpdate(items: SelectorItem[]) {
    const ids = items.map((item) => item.id)
    emit('update:modelValue', ids)
}
const showPlaceholder = computed(() => props.placeholder && !props.modelValue?.length)
const icon = computed(() => (props.teamType === 'data' ? 'users-group' : 'building-skyscraper'))
const count = computed(() => selected.value.length)
</script>
<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="teams"
        multiple
        max-width="sm"
        unselect-item-icon="circle"
        testid="teams-selector"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect', $event)"
        @update:model-value="onModelValueUpdate"
    >
        <template v-if="displayType === 'tags'">
            <DTags
                v-if="selected.length"
                :tags="selected"
                :readonly="false"
                class="cursor-pointer pt-1"
                with-show-more
            />

            <DButton v-else type="ghost">
                <BaseIcon icon="circle" class="text-slate-500" size="md" />
                <span class="truncate">{{ $t('notAssigned') }}</span>
            </DButton>
        </template>
        <DButton v-else-if="displayType === 'button'" :type="buttonType ?? 'ghost'">
            <template v-if="showPlaceholder">
                <BaseIcon :icon="icon" class="text-slate-500" size="md" />
                <span class="truncate">{{ placeholder }}</span>
            </template>
            <template v-else>
                <BaseIcon :icon="icon" size="md" />
                <span class="truncate">
                    {{ selected.length > 1 ? $t(`${props.teamType}TeamsCount`, { count }) : selected[0]?.label }}
                </span>
            </template>
        </DButton>
    </DSelectorGeneric>
</template>
