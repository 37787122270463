import { client } from '@/plugins/trpc'
import { useQuery, useMutation } from '@/composables/vue-query-with-magic'
import type { RouterOutput, RouterInput } from '@/types'

export type CurrentUserOutputType = RouterOutput['userProfile']['currentUser']
export type EditCurrentUserInputType = RouterInput['userProfile']['modify']

export function useProfileQueries() {
    return {
        get() {
            return useQuery({
                queryKey: ['currentUser'],
                queryFn: () => client.userProfile.currentUser.query(),
                queries: ['Person', 'Organization'],
            })
        },
        edit() {
            return useMutation({
                mutationFn: client.userProfile.modify.mutate,
                mutates: ['Person'],
            })
        },
    }
}
