import { type QueryKey, type Query, type Mutation } from '@tanstack/vue-query'
import { type Router } from 'vue-router'
import { useCustomToast, type CustomToastMethods } from '@/composables/toast'
import { type ToastServiceMethods } from 'primevue/toastservice'
import * as Sentry from '@sentry/vue'

export interface ErrorResponseShape {
    message: string
    code: number
    data: {
        code: string
        httpStatus: number
    }
}

type ErrorHandlerConfig =
    | undefined
    | false
    | { toast: 'server-message' | 'default-message' }
    | { toast: 'custom-message'; message: string }
    | { redirectTo: string }
type HttpStatusCode =
    | 400 // Bad Request
    | 404 // Not Found
    | 409 // Conflict
    | 500 // Internal Server Error

export type ErrorHandlers = Record<HttpStatusCode, ErrorHandlerConfig>

const ERROR_HANDLERS_DEFAULTS: ErrorHandlers = {
    404: { redirectTo: '/item-not-found' },
    500: { redirectTo: '/internal-server-error' },
    400: { toast: 'server-message' },
    409: { toast: 'server-message' },
}

type TanStackQuery = Query<unknown, unknown, unknown, QueryKey>
type TanStackMutation = Mutation<unknown, unknown, unknown>

export function getQueryOnErrorHandler(router: Router, toast: ToastServiceMethods) {
    return function queryOnErrorHandler(error: unknown, query: TanStackQuery) {
        return handleError(router, useCustomToast(toast), error, query.meta)
    }
}

export function getMutationOnErrorHandler(router: Router, toast: ToastServiceMethods) {
    return function mutationOnErrorHandler(
        error: unknown,
        variables: unknown,
        context: unknown,
        mutation: TanStackMutation
    ) {
        return handleError(router, useCustomToast(toast), error, mutation.meta)
    }
}

function handleError(
    router: Router,
    toast: CustomToastMethods,
    error: unknown,
    meta: TanStackQuery['meta'] | TanStackMutation['meta']
) {
    if (window.env.SENTRY_ENABLED) {
        // By explicitly calling Sentry.captureException we are sending error data to Sentry _before_
        // potential page redirect caused by error handling. This way we are making sure that the Client
        // and the Server issues are linked in Sentry Issue Trace. If we do not do this, then traces
        // are not linked.
        Sentry.captureException(error)
    }

    if (!isErrorResponseShape(error)) return

    const config: ErrorHandlers = { ...ERROR_HANDLERS_DEFAULTS, ...(meta?.errorHandlers || {}) }
    const { httpStatus } = error.data
    const errorHandler = httpStatus in config && config[httpStatus as keyof ErrorHandlers]

    if (typeof errorHandler !== 'object') return

    if ('toast' in errorHandler && errorHandler.toast === 'default-message') {
        toast.showErrorToast()
    } else if ('toast' in errorHandler && errorHandler.toast === 'server-message') {
        toast.showErrorToast(error.message)
    } else if ('toast' in errorHandler && errorHandler.toast === 'custom-message') {
        toast.showErrorToast(errorHandler.message)
    } else if ('redirectTo' in errorHandler) {
        router.push(errorHandler.redirectTo)
    }
}

function isErrorResponseShape(err: unknown): err is ErrorResponseShape {
    if (typeof err !== 'object' || !err) return false
    if (!('data' in err)) return false
    if (typeof err.data !== 'object' || !err.data) return false

    return 'message' in err && 'code' in err.data && 'httpStatus' in err.data
}
