import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

export default function (app: App, router: Router) {
    Sentry.init({
        app,

        enabled: window.env.SENTRY_ENABLED,
        debug: !!window.env.SENTRY_DEBUG,

        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: window.env.ENV_NAME,
        release: import.meta.env.VITE_SENTRY_RELEASE,

        enableTracing: false,
        tracePropagationTargets: [
            /^https?:\/\/localhost/,
            // relative URLs
            /^\//,
            // branches
            /^https:\/\/.+a.run.app/,
            // non-prod
            /^https:\/\/(?!auth).+\.delight.sh/,
            // prod
            /^https:\/\/delight\.sh\//,
        ],

        integrations: [Sentry.browserTracingIntegration({ router })],
    })
}
