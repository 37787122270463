import { unref, type MaybeRef } from 'vue'
import type { Prisma } from '@prisma/client'
import {
    useQueryClient,
    useMutation as vqUseMutation,
    useQuery as vqUseQuery,
    type UseQueryReturnType,
    type UseQueryDefinedReturnType,
    type UseMutationReturnType,
    type QueryKey,
} from '@tanstack/vue-query'
import {
    type UseQueryExtended,
    type MutationObserverOptionsExtended,
    QueryKeySet,
} from './vue-query-with-magic.helpers'
import { type ErrorResponseShape } from '@/utils/vueQueryGlobalErrorHandling'

// Copied from vue query node_modules\@tanstack\vue-query\build\legacy\useQuery-h0Cr-ata.d.ts
type NonUndefinedGuard<T> = T extends undefined ? never : T

const registry: Partial<Record<Prisma.ModelName, QueryKeySet>> = {}

export function useQuery<
    TQueryFnData = unknown,
    TError = ErrorResponseShape,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    options: UseQueryExtended<TQueryFnData, TError, TData, TQueryFnData, TQueryKey> & {
        initialData?: undefined
    }
): UseQueryReturnType<TData, TError>

export function useQuery<
    TQueryFnData = unknown,
    TError = ErrorResponseShape,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    options: UseQueryExtended<TQueryFnData, TError, TData, TQueryFnData, TQueryKey> & {
        initialData: NonUndefinedGuard<TQueryFnData> | (() => NonUndefinedGuard<TQueryFnData>)
    }
): UseQueryDefinedReturnType<TData, TError>

export function useQuery<
    TQueryFnData = unknown,
    TError = ErrorResponseShape,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(options: UseQueryExtended<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>): UseQueryReturnType<TData, TError> {
    const { queries = [], ...useQueryOptions } = options

    if (queries.length) {
        const { queryKey } = useQueryOptions

        queries.forEach((modelName) => {
            const queryKeys = registry[modelName] || new QueryKeySet()
            registry[modelName] = queryKeys

            queryKeys.add(queryKey)
        })
    }

    return vqUseQuery(useQueryOptions)
}

export function useMutation<TData = unknown, TError = ErrorResponseShape, TVariables = void, TContext = unknown>(
    options: MaybeRef<MutationObserverOptionsExtended<TData, TError, TVariables, TContext>>
): UseMutationReturnType<TData, TError, TVariables, TContext> {
    const queryClient = useQueryClient()

    const { mutates = [], ...useMutationsOptions } = unref(options)
    const originalOnSuccess = unref(useMutationsOptions.onSuccess)

    useMutationsOptions.onSuccess = (data: TData, variables: TVariables, context: TContext) => {
        mutates.forEach((modelName: Prisma.ModelName) => {
            const queryKeys = registry[modelName]
            if (!queryKeys) return

            queryKeys.forEach((queryKey: QueryKey) => {
                queryClient.invalidateQueries({ queryKey, exact: true })
            })
        })

        if (originalOnSuccess) return originalOnSuccess(data, variables, context)
    }

    return vqUseMutation(useMutationsOptions)
}

export { useQueryClient } from '@tanstack/vue-query'
