import PTooltip from 'primevue/tooltip'
import { TOOLTIP_SHOW_DELAY } from '@/constants'

type DTooltip = typeof PTooltip & {
    extend: (name: string, props: Omit<DTooltip, 'extend'>) => DTooltip
}

const Tooltip = (PTooltip as DTooltip).extend('dtooltip', {
    created(el, binding) {
        const { value } = binding
        const val = typeof value === 'string' ? { value } : value
        binding.value = {
            showDelay: TOOLTIP_SHOW_DELAY,
            ...val,
        }
    },
    beforeUpdate(el, binding) {
        const { value } = binding
        const val = typeof value === 'string' ? { value } : value
        binding.value = {
            showDelay: TOOLTIP_SHOW_DELAY,
            ...val,
        }
    },
})

export default Tooltip
export { type TooltipDirectivePassThroughOptions } from 'primevue/tooltip'
