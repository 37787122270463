<script lang="ts" setup>
import { ref, computed } from 'vue'
import type { BusinessGoal } from '@prisma/client'

import type { SelectorItem } from '@/composables/selector'

import BaseIcon from '@/components/BaseIcon.vue'
import DButton, { type DButtonType } from '@/components/DButton.vue'
import DTooltip from '@/components/DTooltip.vue'
import DSelectorGeneric from '@/components/DSelectorGeneric.vue'

const props = defineProps<{
    modelValue?: string[]
    businessGoals?: Pick<BusinessGoal, 'id' | 'label'>[]
    placeholder?: string
    filterPlaceholder?: string
    buttonType?: DButtonType
}>()

const emit = defineEmits<{
    (name: 'update:modelValue', value?: string | string[]): void
    (name: 'select', value: string): void
    (name: 'unselect', value: string): void
}>()

const menuItems = computed(() => {
    const mappedItems =
        props.businessGoals?.map((goal) => ({
            id: goal.id,
            label: goal.label,
        })) ?? []

    return mappedItems as SelectorItem[]
})
const selected = computed(() => menuItems.value.filter(({ id }) => props.modelValue?.includes(id)))

function onModelUpdate(value: SelectorItem[] | undefined) {
    if (!value) return

    const ids = value.map((item) => item.id).filter(Boolean)
    emit('update:modelValue', ids)
}

const businessGoalTooltip = ref()
const isSelectorOpen = ref(false)
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="menuItems"
        multiple
        max-width="sm"
        :filter-placeholder="filterPlaceholder"
        @show="isSelectorOpen = true"
        @hide="isSelectorOpen = false"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect', $event)"
        @update:model-value="onModelUpdate($event)"
    >
        <slot>
            <DButton v-if="!selected || !selected.length" :type="buttonType ?? 'ghost'">
                <BaseIcon v-if="modelValue" icon="businessGoal" class="text-slate-500" size="md" />
                <span class="truncate">{{ placeholder }}</span>
            </DButton>

            <div v-else>
                <DButton
                    id="business-goal"
                    :type="buttonType ?? 'ghost'"
                    class="max-w-56"
                    @mouseenter="businessGoalTooltip?.show"
                    @mouseleave="businessGoalTooltip?.hide"
                >
                    <BaseIcon v-if="modelValue" icon="businessGoal" class="text-slate-500" size="md" />
                    <span class="truncate">
                        {{
                            selected.length > 1
                                ? $t('components.SelectorBusinessGoal.multipleSelected', { count: selected.length })
                                : selected[0]?.label
                        }}
                    </span>
                </DButton>

                <DTooltip v-if="!isSelectorOpen" ref="businessGoalTooltip" placement="top-end" anchor="business-goal">
                    <ul class="flex flex-col gap-2 p-1" :class="{ 'list-inside list-disc': selected.length > 1 }">
                        <li v-for="goal in selected" :key="goal?.id" class="truncate">
                            {{ goal?.label }}
                        </li>
                    </ul>
                </DTooltip>
            </div>
        </slot>
    </DSelectorGeneric>
</template>
