<script lang="ts" setup>
import { computed } from 'vue'

import { useAuthStore } from '@/stores/authStore'
import { useFeatureToggles } from '@/composables/featureToggles'

import DMenuSideNav from '@/components/DMenuSideNav.vue'
import MarkerButton from '@/components/MarkerButton.vue'

const authStore = useAuthStore()
const { list } = useFeatureToggles()

const versionTag = computed(() => window.env.VERSION_TAG)
</script>
<template>
    <div class="flex h-screen w-screen">
        <DMenuSideNav
            :user-roles="authStore.userRoles"
            :feature-toggles="list"
            route="/profile"
            :title="$t('userProfile.title')"
        >
            <template #bottom>
                <div
                    v-tooltip.top="$t('version') + versionTag"
                    class="fixed z-sidebar md:w-40 left-16 bottom-7 sm:w-20 w-10 truncate text-slate-400 text-xsmall"
                >
                    {{ versionTag }}
                </div>
                <MarkerButton />
            </template>
        </DMenuSideNav>
        <div class="flex flex-grow flex-col items-center p-4 overflow-y-scroll">
            <div class="w-main-column 2xl:w-main-column-xl overflow-y-visible">
                <router-view />
            </div>
        </div>
    </div>
</template>
