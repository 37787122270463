<script lang="ts" setup>
import { computed } from 'vue'
import type { ExtendedSize } from '@/composables/sizing'

const props = defineProps<{
    icon: string
    size?: ExtendedSize
    exactSize?: number
    stroke?: number
}>()

const strokeType = {
    thin: 1.5,
    light: 2,
    normal: 3,
}

const mappedSize = computed(() => {
    switch (props.size) {
        case 'xs':
            return 10
        case 'sm':
            return 12
        case 'md':
            return 16
        case 'xl':
            return 24
        case '2xl':
            return 40
        case '3xl':
            return 100
        default:
        case 'lg':
            return 20
    }
})
const computedSize = computed(() => {
    const size = props.exactSize ? props.exactSize : mappedSize.value
    return size / 16 + 'rem'
})
const strokeWidth = computed(() => {
    return mappedSize.value < 16 ? strokeType.thin : mappedSize.value > 20 ? strokeType.normal : strokeType.light
})
</script>

<template>
    <div class="inline-block">
        <component :is="`icon-${icon}`" :data-icon="icon" :size="computedSize" :stroke-width="stroke || strokeWidth" />
    </div>
</template>
