<script lang="ts" setup>
import { ref, computed } from 'vue'
import BaseIcon from './BaseIcon.vue'
import DButton from './DButton.vue'

export type InlineMessageType = 'info' | 'success' | 'warning' | 'error'
export interface DInlineMessageProps {
    text?: string
    message?: string
    type?: InlineMessageType
    button?: {
        text: string
        command: () => void
    }
    closable?: boolean
}

const props = withDefaults(defineProps<DInlineMessageProps>(), {
    type: 'info',
    closable: false,
})

const isVisible = ref(true)

const typesConfig = computed(() => {
    const config = {
        info: {
            background: 'bg-indigo-50',
            text: 'text-indigo-700',
            icon: 'info-circle',
        },
        success: {
            background: 'bg-emerald-50',
            text: 'text-emerald-700',
            icon: 'circle-check',
        },
        warning: {
            background: 'bg-yellow-50',
            text: 'text-yellow-700',
            icon: 'alert-triangle-filled',
        },
        error: {
            background: 'bg-rose-50',
            text: 'text-rose-700',
            icon: 'alert-triangle-filled',
        },
    }

    return {
        bgColor: config[props.type].background,
        textColor: config[props.type].text,
        icon: config[props.type].icon,
    }
})
</script>

<template>
    <div
        v-if="isVisible"
        class="flex relative justify-between items-start gap-x-2 w-full px-3 py-2 min-w-80 rounded min-h-8"
        :class="[typesConfig.bgColor, typesConfig.textColor]"
    >
        <div class="h-6 flex items-center">
            <BaseIcon :icon="typesConfig.icon" size="md" />
        </div>

        <div class="flex justify-between items-center w-full flex-wrap gap-3">
            <div class="flex gap-x-3 items-center gap-y-2 flex-wrap min-h-6">
                <div v-if="text" class="flex min-h-6 items-center text-small-medium">
                    {{ text }}
                </div>
                <div v-if="message" class="flex min-h-6 items-center text-small">
                    {{ message }}
                </div>
            </div>
            <div v-if="button || $slots.default" class="flex gap-x-1">
                <slot>
                    <DButton v-if="button" type="ghost" class="pl-0" @click="button.command()">
                        {{ button.text }}
                    </DButton>
                </slot>
            </div>
        </div>

        <div v-if="closable" class="h-6 flex items-center">
            <BaseIcon icon="x" size="md" class="cursor-pointer" @click="isVisible = false" />
        </div>
    </div>
</template>
