import { type ToastServiceMethods } from 'primevue/toastservice'
import { useToast } from 'primevue/usetoast'
import i18n from '@/plugins/i18n'

export const useCustomToast = (toast: ToastServiceMethods = useToast()) => {
    const { t } = i18n.global
    const TOAST_LIFE_TIME = 5000

    return {
        showSuccessToast(detail = t('toast.success.detail')) {
            toast.add({
                severity: 'success',
                summary: t('toast.success.summary'),
                detail,
                life: TOAST_LIFE_TIME,
            })
        },
        showErrorToast(detail = t('toast.error.detail')) {
            toast.add({
                severity: 'error',
                summary: t('toast.error.summary'),
                detail,
                life: TOAST_LIFE_TIME,
            })
        },
    }
}

export type CustomToastMethods = ReturnType<typeof useCustomToast>
