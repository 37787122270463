<script lang="ts" setup>
/*
    This component uses the floating-ui library for vue to position the tooltip.
*/
import { ref, computed, toRef, useSlots, onBeforeUnmount, shallowRef } from 'vue'
import {
    useFloating,
    offset as floatingOffset,
    flip,
    shift,
    arrow as arrowHandler,
    autoUpdate,
    type OffsetOptions,
} from '@floating-ui/vue'

import { onClickOutside } from '@vueuse/core'

import { TOOLTIP_SHOW_DELAY } from '@/constants'

const props = withDefaults(
    defineProps<{
        content?: string
        anchor?: string | HTMLElement
        offset?: OffsetOptions
        trigger?: 'hover' | 'click'
        placement?: 'top' | 'right' | 'bottom' | 'left' | 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end'
        delayMs?: number
        arrow?: boolean
        inline?: boolean
        anchorClass?: string
        useDefaultClass?: boolean
        ignoreElements?: string[]
        arrowClass?: string
        testid?: string
    }>(),
    {
        offset: 10,
        arrow: true,
        trigger: 'hover',
        delayMs: TOOLTIP_SHOW_DELAY,
        inline: false,
        useDefaultClass: true,
        testid: 'd-tooltip',
        ignoreElements: () => [],
    }
)

const anchorRef = shallowRef<HTMLElement>()
const popover = shallowRef<HTMLElement>()
const popoverArrow = shallowRef<HTMLElement>()
const showPopover = shallowRef(false)

const slots = useSlots()

function definePosition() {
    if (props.inline || !!slots?.anchor) return

    /*
        The floating element can be positioned relative to a 'virtual' reference element - the anchor element.
        The anchor does not need to live inside this the component.
    */
    const anchorEl = props.anchor instanceof HTMLElement ? props.anchor : document.getElementById(props.anchor ?? '')
    if (!anchorEl) return
    anchorEl.classList.add('anchor')
    const anchorRect = anchorEl.getBoundingClientRect()

    anchorRef.value = {
        ...anchorEl,
        getBoundingClientRect() {
            return anchorRect
        },
    }
}

const offsetRef = toRef(() => props.offset)
const middleware = ref([floatingOffset(offsetRef.value), flip(), shift(), arrowHandler({ element: popoverArrow })])

const {
    placement: computedPlacement,
    middlewareData,
    floatingStyles,
} = useFloating(anchorRef, popover, {
    placement: toRef(() => props.placement),
    whileElementsMounted: props.trigger === 'click' ? autoUpdate : undefined, // autoUpdate is costly - only use for click-trigger where the tooltip is always visible
    middleware,
})

/* Arrow */
const arrowX = computed(() => middlewareData.value.arrow?.x ?? null)
const arrowY = computed(() => middlewareData.value.arrow?.y ?? null)

const arrowStyles = computed(() => {
    const arrowSize = 7
    const side = computedPlacement.value.split('-')[0]
    const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
    }[side]

    return {
        width: `${arrowSize}px`,
        height: `${arrowSize}px`,
        left: arrowX.value !== null ? `${arrowX.value}px` : '',
        top: arrowY.value !== null ? `${arrowY.value}px` : '',
        [`${staticSide}`]: `${-arrowSize / 2}px`,
        transform: `rotate(45deg)`,
    }
})

let timer: ReturnType<typeof setTimeout>

const show = () => {
    definePosition()
    timer = setTimeout(() => {
        showPopover.value = true
    }, props.delayMs)
}

const hide = () => {
    showPopover.value = false
    if (timer) clearTimeout(timer)
}

const toggle = () => {
    if (!props.anchor && !anchorRef.value) return
    if (showPopover.value) hide()
    else show()
}

const methods = props.anchor && !props.inline ? { toggle, show, hide } : {}

defineExpose(methods)

/* Click outside handler should only be used for click-trigger - this reduces no. of DOM event handlers */
if (props.trigger === 'click') {
    onClickOutside(popover, hide, { ignore: ['.anchor', ...props.ignoreElements] })
}

onBeforeUnmount(() => {
    if (timer) clearTimeout(timer)
})
</script>

<template>
    <div :data-testid="testid" class="w-fit">
        <div
            v-if="$slots.anchor || inline"
            ref="anchorRef"
            class="anchor"
            @click="trigger === 'click' && toggle()"
            @mouseenter="trigger === 'hover' && show()"
            @mouseleave="trigger === 'hover' && hide()"
        >
            <slot name="anchor">
                <div :class="anchorClass">{{ anchor }}</div>
            </slot>
        </div>

        <Transition name="floating-fade-in">
            <div
                v-if="($slots.default || content) && showPopover"
                ref="popover"
                :date-testid="testid + '-floating'"
                :style="floatingStyles"
                class="z-tooltip"
                :class="{
                    'shadow-elevation-low text-xsmall min-w-[64px] max-w-[420px] break-words rounded bg-slate-100 px-2 py-1 text-slate-700':
                        useDefaultClass,
                }"
            >
                <slot>{{ content }}</slot>
                <div
                    v-if="arrow"
                    id="popoverArrow"
                    ref="popoverArrow"
                    :style="arrowStyles"
                    class="absolute"
                    :class="arrowClass ? arrowClass : 'bg-slate-100'"
                />
            </div>
        </Transition>
    </div>
</template>

<style scoped lang="css">
.floating-fade-in-enter-active {
    @apply transition-opacity duration-300;
}
.floating-fade-in-enter-from,
.floating-fade-in-leave-to {
    @apply opacity-0 duration-300;
}
.floating-fade-in-enter-to,
.floating-fade-in-leave-from {
    @apply opacity-100;
}
</style>
