<script lang="ts" setup>
import { computed } from 'vue'

import AvatarGroup from 'primevue/avatargroup'
import { type AvatarPassThroughOptions } from 'primevue/avatar'
import type { PassThrough } from 'primevue/ts-helpers'

import BaseIcon from './BaseIcon.vue'
import type { ExtendedSize } from '@/composables/sizing'

const props = withDefaults(
    defineProps<{
        users: { id?: string; photoUrl?: string | null }[]
        size?: ExtendedSize
        selectedUserId?: string
        max?: number
    }>(),
    {
        size: 'md',
    }
)

const mappedSize = computed(() => {
    switch (props.size) {
        case 'xs':
            return 16 / 16 + 'rem'
        case 'sm':
            return 20 / 16 + 'rem'
        case 'lg':
            return 28 / 16 + 'rem'
        case 'xl':
            return 32 / 16 + 'rem'
        case '2xl':
            return 64 / 16 + 'rem'
        case '3xl':
            return 128 / 16 + 'rem'
        case 'md':
        default:
            return 24 / 16 + 'rem'
    }
})

const defaultImage = 'https://source.boringavatars.com/marble/50'

const avatarPT = computed<PassThrough<AvatarPassThroughOptions>>(() => ({
    root: {
        style: `height: ${mappedSize.value}; width: ${mappedSize.value}`,
        class: [
            {
                'border border-dashed border-slate-400': props.users.length === 0,
                'border-none': props.users.length === 1,
                'border-2 bg-slate-100': props.users.length > 1,
            },
        ],
    },
}))
</script>

<template>
    <AvatarGroup class="flex -space-x-3">
        <template v-for="user in users.slice(0, max)" :key="user.id">
            <PAvatar v-if="user.photoUrl" :image="user.photoUrl || defaultImage" shape="circle" :pt="avatarPT" />
            <PAvatar v-else shape="circle" :pt="avatarPT">
                <template #icon>
                    <BaseIcon icon="user" class="text-slate-500" :size="size" />
                </template>
            </PAvatar>
        </template>
        <PAvatar v-if="users.length === 0" shape="circle" :pt="avatarPT">
            <template #icon>
                <BaseIcon icon="user" class="text-slate-500" :size="size" />
            </template>
        </PAvatar>
    </AvatarGroup>
</template>
