import { unref } from 'vue'
import type { Prisma } from '@prisma/client'
import { hashKey, type QueryKey, type MutationObserverOptions, type UseQueryOptions } from '@tanstack/vue-query'
import type { ErrorHandlers } from '@/utils/vueQueryGlobalErrorHandling'

export class QueryKeySet extends Set<QueryKey> {
    #hashedKeys: Set<string>

    constructor() {
        super()
        this.#hashedKeys = new Set()
    }

    add(value: QueryKey): this {
        const unrefValue = value.map((key) => unref(key))
        const k = hashKey(unrefValue)
        if (this.#hashedKeys.has(k)) return this

        this.#hashedKeys.add(k)
        super.add(value)

        return this
    }
}

export type UseQueryExtended<TQueryFnData, TError, TData, TQueryData, TQueryKey extends QueryKey> = UseQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryData,
    TQueryKey
> & {
    queryKey: TQueryKey
    queries: Prisma.ModelName[]
    meta?: {
        errorHandlers?: Partial<ErrorHandlers>
    } & Record<string, unknown>
}

export type MutationObserverOptionsExtended<TData, TError, TVariables, TContext> = MutationObserverOptions<
    TData,
    TError,
    TVariables,
    TContext
> & {
    mutates: Prisma.ModelName[]
    meta?: {
        errorHandlers?: Partial<ErrorHandlers>
    } & Record<string, unknown>
}
