import { client } from '@/plugins/trpc'

export function useLumoQueries() {
    return {
        expandOpportunityDescription(
            label: string,
            description: string,
            onData: (value: string) => void,
            onError: (error: Error) => void,
            onComplete: () => void
        ) {
            return client.copilot.opportunity.expandDescription.subscribe(
                {
                    label,
                    description,
                },
                {
                    onData,
                    onError,
                    onComplete,
                }
            )
        },

        expandDataProductDescription(
            label: string,
            description: string,
            onData: (value: string) => void,
            onError: (error: Error) => void,
            onComplete: () => void
        ) {
            return client.copilot.dataProduct.expandDescription.subscribe(
                {
                    label,
                    description,
                },
                {
                    onData,
                    onError,
                    onComplete,
                }
            )
        },
    }
}
