import type { ConfirmationOptions } from 'primevue/confirmationoptions'
import { useConfirm } from 'primevue/useconfirm'

export const useDialog = () => {
    const confirm = useConfirm()
    return {
        asyncRequire(options: ConfirmationOptions): Promise<boolean> {
            return new Promise((resolve) => {
                confirm.require({ ...options, accept: () => resolve(true), reject: () => resolve(false) })
            })
        },
        require(options: ConfirmationOptions): void {
            return confirm.require(options)
        },
    }
}
