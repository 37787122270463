import { watch } from 'vue'
import markerSDK, { type MarkerSdk } from '@marker.io/browser'
import { useAuthStore } from '@/stores/authStore'
import { storeToRefs } from 'pinia'

export let marker: MarkerSdk

export default {
    install: async () => {
        // Only show the widget if the user is authenticated
        const authStore = useAuthStore()
        const { isAuthenticated } = storeToRefs(authStore)
        watch(
            isAuthenticated,
            async (authenticated) => {
                if (!authenticated || marker) return
                const user = authStore.user
                marker = await markerSDK.loadWidget({
                    project: window.env.MARKER_PROJECT_ID,
                    silent: true,
                    reporter: user?.email
                        ? {
                              email: user.email,
                              fullName: user.name ?? '',
                          }
                        : undefined,
                })
            },
            { immediate: true }
        )
    },
}
