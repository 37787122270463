import { EntityTypeEnum } from '@prisma/client'

export function getTrackEntityCreatedType(enityType: EntityTypeEnum) {
    const entityTypeToTrackType: Record<EntityTypeEnum, string> = {
        Opportunity: 'Opportunity',
        AnalyticsProduct: 'Analytics Product',
        DataAsset: 'Data Asset',
        SmartProduct: 'Smart Product',
        TechProduct: 'Tech Product',
    }

    return entityTypeToTrackType[enityType]
}
