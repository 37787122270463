<script setup lang="ts">
import { computed } from 'vue'

import type { PassThrough } from 'primevue/ts-helpers'
import type { CheckboxPassThroughOptions, CheckboxPassThroughMethodOptions } from 'primevue/checkbox'
import type { Size } from '@/composables/sizing'

import BaseIcon from './BaseIcon.vue'

export type CheckboxOption = {
    id: string
    label: string
    value?: string | boolean
}

const props = withDefaults(
    defineProps<{
        options?: CheckboxOption | CheckboxOption[]
        modelValue?: CheckboxOption[] | null | boolean
        sectionLabel?: string
        error?: string
        direction?: 'row' | 'col'
        size?: Size
        truncate?: boolean
        disabled?: boolean
    }>(),
    {
        modelValue: null,
        size: 'md',
        direction: 'row',
        truncate: false,
        disabled: false,
    }
)
const emit = defineEmits(['update:modelValue'])

const mappedSize = computed(() => {
    return props.size === 'sm' ? 'xs' : props.size === 'md' ? 'sm' : 'md'
})

const checkboxPT = computed<PassThrough<CheckboxPassThroughOptions>>(() => ({
    root: {
        class: [
            `cursor-pointer inline-flex relative select-none align-bottom`,
            { 'translate-y-0.5': areOptionsAvailable.value },
        ],
    },
    input: {
        class: [
            'peer',

            // Size
            'w-full ',
            'h-full',

            // Position
            'absolute',
            'top-0 left-0',
            'z-10',

            // Spacing
            'p-0',
            'm-0',

            // Shape
            'opacity-0',
            'rounded-md',
            'outline-none',
            'border-2 border-slate-500',

            // Misc
            'appearance-none',
            'cursor-pointer',
        ],
    },
    box: ({ context }: CheckboxPassThroughMethodOptions) => [
        'flex items-center justify-center border transition-colors duration-200 rounded',
        props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        {
            'size-3': props.size === 'sm',
            'size-4': props.size === 'md',
            'size-5': props.size === 'lg',
        },
        {
            'border-slate-300 bg-slate-200 text-slate-400': context.checked && props.disabled,
            'bg-slate-500 border-slate-500': context.checked && !props.disabled,
            'bg-slate-200 border-slate-300': !context.checked && props.disabled,
            'border-slate-500 bg-white': !context.checked && !props.disabled,
        },
    ],
}))

const availableOptions = computed(() => {
    if (!props.options) return []
    if (Array.isArray(props.options)) return props.options
    return [props.options]
})

const areOptionsAvailable = computed(() => availableOptions.value && availableOptions.value.length)

const onToggle = (event: Event) => {
    if (props.disabled) return
    emit('update:modelValue', event)
}
</script>

<template>
    <div v-if="areOptionsAvailable" class="flex w-full flex-col" :class="[direction === 'row' ? 'gap-y-2' : 'gap-y-3']">
        <span v-if="sectionLabel" class="text-sm text-slate-500">{{ sectionLabel }}</span>
        <div
            class="flex w-full gap-y-3"
            :class="[direction === 'row' ? 'flex-row flex-wrap items-center gap-x-6' : 'flex-col']"
        >
            <div
                v-for="option in availableOptions"
                :key="option.id"
                class="grid grid-cols-[min-content_minmax(0,max-content)] gap-x-1"
            >
                <PCheckbox
                    :input-id="option?.id"
                    :model-value="modelValue"
                    :value="option"
                    unstyled
                    :pt="checkboxPT"
                    :data-testid="option?.value"
                    @update:model-value="onToggle"
                >
                    <template #icon="{ checked }">
                        <BaseIcon
                            v-if="checked"
                            icon="check"
                            :size="mappedSize"
                            class="transition-all duration-200"
                            :class="[disabled ? 'text-slate-400' : 'text-white']"
                        />
                    </template>
                </PCheckbox>
                <label
                    v-if="option?.label"
                    :for="option?.id"
                    class="w-full text-slate-500"
                    :class="[
                        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                        truncate ? 'truncate' : 'break-words',
                        { 'text-xs': size === 'sm' },
                        { 'text-sm': size === 'md' },
                        { 'text-md': size === 'lg' },
                    ]"
                >
                    {{ option?.label }}
                </label>
            </div>
        </div>

        <span v-if="error" class="text-xs text-rose-500">{{ error }}</span>
    </div>
    <PCheckbox v-else :model-value="modelValue" binary unstyled :pt="checkboxPT" @update:model-value="onToggle">
        <template #icon="{ checked }">
            <BaseIcon
                v-if="checked"
                icon="check"
                :size="mappedSize"
                class="transition-all duration-200"
                :class="[disabled ? 'text-slate-400' : 'text-white']"
            />
        </template>
    </PCheckbox>
</template>
