import { useHeap } from '@/plugins/heap'
import type { RouteLocationNormalized } from 'vue-router'

/** Auth0 redirects the user back to our page and might report problems we need to take care of */
export const handleAuth0CallbackErrors = (to: RouteLocationNormalized) => {
    const { track } = useHeap()
    // Invalid Request
    if (to.query?.error === 'invalid_request') {
        // Check for specific error messages
        if (to.query?.error_description && to.query?.error_description.includes('requires organization membership')) {
            track('Login Error - No organization')
            return { name: 'no-organization-error' }
        }
        if (to.query?.error_description?.includes('invitation not found or already used')) {
            track('Login Error - Invitation expired')
            return { name: 'invitation-expired' }
        }

        // Auth0 error: see https://community.auth0.com/t/organizations-is-not-supported-together-with-primary-user-modifications/122263
        if (
            to.query?.error_description?.includes(
                'organizations is not supported together with primary user modifications in rules'
            )
        ) {
            track('SSO: Account Linking completed')
            return { name: 'account-linking-completed' }
        }

        track('Login Error - General error')
        return { name: 'sign-in-error' }
    }
    return
}
